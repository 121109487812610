<app-nav-bar2></app-nav-bar2>
<div class="container">
    <div class="row">
        <div style="margin-bottom: 60px;" class="col-md-12 col-sm-12 col-xs-12">
            <h2 style="margin-top: 150px; font-size: 24px; " class="shop-men-women text-center">Terms and Conditions
            </h2>
            <br>
            <p class="text-muted" style="margin-top: 40px;">
                The following “Terms and Conditions” that will govern the services provided by the company to their
                clients is bound to its applicable service schedule executed by and between the parties concerned. </p>

            <ul>
                <li class="text-muted">The services that will be provided under these terms and conditions will be the
                    ones that are set
                    forth in services that are mutually agreed upon. The services might include</li>
                <ol class="text-muted"> <br>
                    <li>Advisory, design, and strategic consulting services that relates to any IT needs as per client
                        agreement.</li> <br>
                    <li>Implementation, configuration, and other related consultation received by the client to be
                        rightfully used will be under a different/separate agreement.
                        <p class="text-muted">to be rightfully used will be under a different/separate agreement.</p>
                        The services provided shall effectively be under the governance of these terms and conditions.
                        The client warrants that the one executing the services is an authorized representative of the
                        client.
                    </li>
                </ol> <br>


                <li class="text-muted">The services provided would be charged and invoiced fully. The fees listed are
                    total fees that also
                    is what the service is charged for and won’t likely differ unless and until it’s specified while the
                    service is being offered. In case there are changes affecting the time or performance of the service
                    provided which is under the same applicable service, adjustments to compensate is to be paid and
                    therefore it shall be agreed to before such modifications are under effect.</li> <br>

                <li class="text-muted">The terms and conditions, therefore shall remain active and in effect from the
                    date of its effect
                    specified in the service contract with its completion of service discussed in the same schedule. The
                    service schedule in effect with respect to its service will be active with its date of effectiveness
                    as specified in the schedule and will be ineffective through its completion. The contract is liable
                    to termination with a written notice given to either of the parties concerned upon a material breach
                    by the other party within thirty days of the notice that describes the breach. Clients would be
                    liable to pay for the services that are rendered through the termination date and shall receive
                    compensation for any prepaid fees rendered through the service</li> <br>

                <li class="text-muted">The client is the sole proprietor and has the exclusive right to all their
                    personal information,
                    material, third party data and third-party account information, that includes intellectual property
                    rights.</li> <br>

                <li class="text-muted">The client is the sole proprietor and has the exclusive right to all their
                    personal information,
                    material, third party data and third-party account information, that includes intellectual property
                    rights.</li>
            </ul>
        </div>

    </div>

</div>
<app-footer></app-footer>