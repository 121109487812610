<body class="hero-anime" style="overflow: hidden; ">

	<div class="navigation-wrap bg-light start-header start-style">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<nav class="navbar navbar-expand-md navbar-light ">

						<a class="navbar-brand" href="#" target="">
							<img style="width: 120px; height: auto;" src="../../../assets/img/logo_softnetworld.png"
								alt="">

						</a>

						<button class="navbar-toggler" type="button" data-toggle="collapse"
							data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span class="navbar-toggler-icon"></span>
						</button>

						<div class="collapse navbar-collapse" id="navbarSupportedContent">
							<ul class="navbar-nav ml-auto py-4 py-md-0">

								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" href="#"><i class="icofont-home"></i>
										Home</a>
								</li>

								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4 active">
									<a style="color: black !important;" class="nav-link dropdown-toggle"
										data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
										aria-expanded="false"><i class="icofont-settings-alt"></i> Services <i
											class="icofont-rounded-down"></i></a>
									<div class="dropdown-menu" style="font-size: small;">
										<a class="dropdown-item" [routerLink]="['/linux-services']">Linux Services</a>
										<a class="dropdown-item" [routerLink]="['/window-services']">Windows
											Services</a>
										<a class="dropdown-item" [routerLink]="['/ecoomerse-business']">Ecommerce
											Business</a>
										<a class="dropdown-item" [routerLink]="['/branding-services']">Branding
											Services</a>
										<a class="dropdown-item" [routerLink]="['/cyber-security']">Cyber Security</a>
										<a class="dropdown-item" [routerLink]="['/email-services']">Email services</a>
										<a class="dropdown-item" [routerLink]="['/digital-marketing']">Digital
											marketing</a>
										<a class="dropdown-item" [routerLink]="['/logo-design']">Logo design</a>
										<a class="dropdown-item" [routerLink]="['/cloud-services-and-support']">Cloud
											Services & Support</a>
										<a class="dropdown-item" [routerLink]="['/devops']">DevOps</a>
										<a class="dropdown-item" [routerLink]="['/docker']">Docker</a>
										<a class="dropdown-item" [routerLink]="['/Kubernetes']">Kubernetes</a>
										<a class="dropdown-item" [routerLink]="['/wordpress-joomla']">Wordpress &
											Joomla</a>
										<a class="dropdown-item" [routerLink]="['/social-media-marketing']">Social Media
											Marketing </a>
										<a class="dropdown-item" [routerLink]="['/web-development-and-designing']">Web
											Development & Designing </a>
										<a class="dropdown-item" [routerLink]="['/job-support-and-training']">Job
											Support & Training Services</a>
									</div>
								</li>


								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" [routerLink]="['/portfolio']"><i class="icofont-queen"></i>
										Portfolio</a>
								</li>
								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" [routerLink]="['/about-us']"><i class="icofont-fire-burn"></i>
										About Us</a>
								</li>
								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
										aria-haspopup="true" aria-expanded="false"><i class="icofont-safety"></i> Our
										Terms <i class="icofont-rounded-down"></i></a>
									<div class="dropdown-menu">
										<a class="dropdown-item" [routerLink]="['/terms-&-condition']">Terms &
											Condition</a>
										<a class="dropdown-item" [routerLink]="['/privacy-policy']">Privacy Policy</a>
										<!-- <a class="dropdown-item" href="#">Something else here</a>
										<a class="dropdown-item" href="#">Another action</a> -->
									</div>
								</li>
								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" [routerLink]="['/career']"><i class="icofont-tie"></i>
										Career</a>
								</li>
								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" [routerLink]="['/contact-us']"><i class="icofont-phone"></i> Contact</a>
								</li>
							</ul>
						</div>

					</nav>
				</div>
			</div>
		</div>
	</div>


























</body>