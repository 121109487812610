<!-- NavBar Scrolling left to right section Start-->


<!-- NavBar Scrolling left to right section End-->

<!-- Header Start -->

<body class="hero-anime">

	<div class="navigation-wrap bg-light start-header start-style">
		<main style="margin-top: -20px;">
			<div class="container-fluid">
				<div class="row no-gutters">
					<div class="col-md-12">
						<div class="d-flex justify-content-between align-items-center breaking-news "
							style="background-color: black; color: white;">
							<div
								class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1 news">
								<span style="font-size: small;" class="bx-fade-right d-flex align-items-center">NOTICE
									&nbsp; <i class='bx bxs-right-arrow bx-fade-right'></i></span>
							</div>
							<marquee class="news-scroll" behavior="scroll" direction="left" onmouseover="this.stop();"
								onmouseout="this.start();">
								<a target="_blank" href="https://www.kalyankarisoftnet.com/">We are child company of kalyankari softnet Pvt. Ltd "www.kalyabkarisoftnet.com", Softnet world is one of the best IT company in the india. We have collabration with many premium firm in the India, USA, Canada and many more. </a>
								<span class="dot"></span>
								<a href="#">Softnet World Pvt. Ltd. is one of the premium website building company in your area, We will provide 360 degree boost in growth of your business.
								</a>
								<span class="dot"></span>
								<a href="#">Contact Us : +91 9607926665 +91 8882004118 info@softnetworld.com  199, PKT-11 Sector A-6, Narela, Delhi, 110040 New Delhi, India</a>
							</marquee>
						</div>
					</div>
				</div>
			</div>

		</main>
		<div class="container">
			<div class="row">
				<div class="col-12">

					<nav class="navbar navbar-expand-md navbar-light">

						<a class="navbar-brand" href="#" target="_blank"><img
								src="../../../assets/img/logo_softnetworld.png" alt="softnet world logo"></a>

						<button class="navbar-toggler" type="button" data-toggle="collapse"
							data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span class="navbar-toggler-icon"></span>
						</button>

						<div class="collapse navbar-collapse" id="navbarSupportedContent">
							<ul class="navbar-nav ml-auto py-4 py-md-0">

								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" href="#"><i class="icofont-home"></i>
										Home</a>
								</li>

								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" [routerLink]="['/portfolio']"><i class="icofont-queen"></i>
										Portfolio</a>
								</li>
								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" [routerLink]="['/about-us']"><i class="icofont-fire-burn"></i>
										About Us</a>
								</li>

								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
										aria-haspopup="true" aria-expanded="false"><i class="icofont-safety"></i> Our
										Terms <i class="icofont-rounded-down"></i></a>
									<div class="dropdown-menu">
										<a class="dropdown-item" [routerLink]="['/terms-&-condition']">Terms &
											Condition</a>
										<a class="dropdown-item" [routerLink]="['/privacy-policy']">Privacy Policy</a>
										<!-- <a class="dropdown-item" href="#">Something else here</a>
										<a class="dropdown-item" href="#">Another action</a> -->
									</div>
								</li>
								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4 active">
									<a style="color: black !important;" class="nav-link dropdown-toggle"
										data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
										aria-expanded="false"><i class="icofont-settings-alt"></i> Services <i
											class="icofont-rounded-down"></i></a>
									<div class="dropdown-menu" style=" font-size: small">
										<a class="dropdown-item" [routerLink]="['/linux-services']">Linux Services</a>
										<a class="dropdown-item" [routerLink]="['/window-services']">Windows
											Services</a>
										<a class="dropdown-item" [routerLink]="['/ecoomerse-business']">Ecommerce
											Business</a>
										<a class="dropdown-item" [routerLink]="['/branding-services']">Branding
											Services</a>
										<a class="dropdown-item" [routerLink]="['/cyber-security']">Cyber Security</a>
										<a class="dropdown-item" [routerLink]="['/email-services']">Email services</a>
										<a class="dropdown-item" [routerLink]="['/digital-marketing']">Digital
											marketing</a>
										<a class="dropdown-item" [routerLink]="['/logo-design']">Logo design</a>
										<a class="dropdown-item" [routerLink]="['/cloud-services-and-support']">Cloud
											Services & Support</a>
										<a class="dropdown-item" [routerLink]="['/devops']">DevOps</a>
										<a class="dropdown-item" [routerLink]="['/docker']">Docker</a>
										<a class="dropdown-item" [routerLink]="['/Kubernetes']">Kubernetes</a>
										<a class="dropdown-item" [routerLink]="['/wordpress-joomla']">Wordpress &
											Joomla</a>
										<a class="dropdown-item" [routerLink]="['/social-media-marketing']">Social Media
											Marketing </a>
										<a class="dropdown-item" [routerLink]="['/web-development-and-designing']">Web
											Development & Designing </a>
										<a class="dropdown-item" [routerLink]="['/job-support-and-training']">Job
											Support & Training Services</a>
									</div>
								</li>
								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" [routerLink]="['/career']"><i class="icofont-tie"></i>
										Career</a>
								</li>
								<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
									<a class="nav-link" [routerLink]="['/contact-us']"><i class="icofont-phone"></i> Contact</a>
								</li>
							</ul>
						</div>

					</nav>
				</div>
			</div>
		</div>
	</div>
	<div class="section full-height" >
		<div class="absolute-center">
			<div class="section">
				<div class="container-fluid">
					<div class="row" style="margin-bottom: 40px;">
						
							<!-- home video section start -->
							<div class="bg-video-wrap" >
								<video src="../../../assets/img/herovideo.mp4" loop muted autoplay>
								</video>
								<div class="overlay">
								</div>
								<h1>Robost Software Development Center
								</h1>
								
							  </div>

							  
							
						
					</div>
				</div>
			</div>
			<div class="section mt-5">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div id="switch">
								<div id="circle"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="my-5 py-5">
	</div>
	<!-- Header End -->