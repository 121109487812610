<app-hero></app-hero>

<app-service-comp></app-service-comp>

<app-why-us></app-why-us>

<app-technology-used></app-technology-used>



<app-features></app-features>

<app-testimonal></app-testimonal>



<app-footer></app-footer>