<app-nav-bar2></app-nav-bar2>

<div class="container">
    <div class="row" style="margin-top: 10em; padding-bottom: 40px;">

        <div class="col-md-12">

            <div class="text-justify">
                <h3 style="font-weight: 800;" class="text-uppercase">Docker</h3>
                <p>Docker is a free and open platform for creating, shipping, and running applications. Docker allows
                    you to decouple your applications from your infrastructure, allowing you to deliver software more
                    rapidly.</p>

                <p>Docker is an outstanding tool that uses the influence of virtualization to deliver the service in
                    packages known as containers. When you use this framework to evaluate your software delivery
                    process, you will notice that your resources, applications, and infrastructures, will gain agility,
                    performance, speed, scalability, and mobility in a safe environment while also lowering costs. This
                    is the path to take in order to attain opportunities for business improvement.</p>
            </div>

        </div>

        <div class="col-md-12">
            <div style="position: relative; float: right; margin-top: 30px;">
                <img class="img-fluid" src="../../assets/img/orchestratordeployment.gif" alt="">

            </div>

        </div>

    </div>


    <div class="row">
        <div class="col-md-6">

            <div class="text-justify" style="margin-top: 70px;">
                <h4 style="font-weight: 700;" >One of the most important advantages of using Docker Services in your
                    company applications is:</h4>
                <h6 style="font-weight: 500; font-size: 14px; margin-top: 20px;">DOCKER SERVICES PROVIDES RAPID AND
                    CONSISTENT APPLICATION DELIVERY</h6>
                <p style="margin-top: 20px;">Docker simplifies the development lifecycle by allowing developers to work
                    in systematic environments
                    while using local containers to deliver your applications and services. Containers are ideal for
                    workflows involving continuous integration and continuous delivery (CI/CD).</p>
            </div>

        </div>

        <div class="col-md-6">
            <div style="position: relative; float: right; margin-bottom: 30px;">
                <img class="img-fluid" src="../../assets/img/docker.gif" alt="docker">

            </div>
        </div>

        <div class="col-md-12">
            <p class="text-justify" style="margin-bottom: 60px;">The professional DevOps engineers in our company have years of industry experience in developing
                functional, portable, and scalable Docker-based cloud development tools that are able to work in
                dispersed IT environments and offer your company the best cloud computing technology. We assist you in
                efficiently using Docker to transform your homogeneous systems into container-ready agile systems,
                allowing you to accomplish the speed and strength you require in your software delivery process.</p>
        </div>

    </div>


</div>

<app-footer></app-footer>