import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonal',
  templateUrl: './testimonal.component.html',
  styleUrls: ['./testimonal.component.css']
})
export class TestimonalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
