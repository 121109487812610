<div class="container" style="background-color: #171f32; color: whitesmoke; padding-top: 7px; padding-bottom: 40px;">
    <div class="row" style="margin-top: 40px;">
        <div class="col-md-12">
           <h2 class="text-center my-3" style="font-size: 20px; font-weight: 800; ">OTHER SERVICES</h2>
           <div class="text-center">
            <img src="../../assets/img/heading-line-white.png" alt="">
           </div>
        </div>

        <div class="col-md-12">
            <ul>
                <li data-aos="fade-right" ><a style="border-bottom: 1px solid white !important; " href="">Linux Services</a></li> 
                <li data-aos="fade-left" style="border-bottom: 1px solid white !important; "><a href="">Windows Services</a></li> 
                <li data-aos="fade-right" style="border-bottom: 1px solid white;"><a href="">Ecommerce Services</a></li> 
                <li data-aos="fade-left" style="border-bottom: 1px solid white;"><a href="">Branding Services</a></li> 
                <li data-aos="fade-right" style="border-bottom: 1px solid white;"><a href="">Cyber Security</a></li> 
                <li data-aos="fade-left" style="border-bottom: 1px solid white;"><a href="">Email Services</a></li> 
                <li data-aos="fade-right" style="border-bottom: 1px solid white;"><a href="">Digital Marketing</a></li> 
                <li data-aos="fade-left" style="border-bottom: 1px solid white;"><a href="">Logo Design</a></li> 
                <li  data-aos="fade-right" style="border-bottom: 1px solid white;"><a href="">Cloud Services and Support</a></li> 
                <li data-aos="fade-left" style="border-bottom: 1px solid white;"><a href="">Social Media Marketing</a></li> 
                <li data-aos="fade-right" style="border-bottom: 1px solid white;"><a href="">Web Development and Designing</a></li> 
                <li data-aos="fade-left" style="border-bottom: 1px solid white;"><a href="">Job support & Traning Services</a></li>
            </ul>

        </div>

    </div>

</div>
