<!-- ======= Why Us Section ======= -->
<section class="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
  <div class="container">

    <div class="row">
      <div class="col-lg-6 video-box">
        <img src="assets/img/why-us.jpg" class="img-fluid" alt="">
        <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="venobox play-btn mb-4" data-vbtype="video"
          data-autoplay="true"></a>
      </div>

      <div class="col-lg-6 d-flex flex-column justify-content-center p-5">
        <div class="icon-box">

          <h4 class="title"><a href="">why softnet world ?</a></h4>
          <p class="description" style="text-align: justify; ">At Soft Net World, we believe that there is an incredible
            way of doing software
            services. We are obsessively passionate about the software services of our company, and help people
            accomplish the required growth in their business by utilizing the services provided by us.</p>
        </div>

        <div class="icon-box">



          <div class="description" style="font-size: 13px; margin-top: -20px;">
            <ul class="liststyle">
              <li> <i class='bx bxs-award bx-tada'></i> Awarded Best Website Designing Company .</li>
              <li><i class='bx bxs-diamond bx-tada'></i> We have an experience of 5+ years in the IT industry.</li>
              <li><i class='bx bxs-graduation bx-tada'></i> Vertical and Domain Expertise.</li>
              <li> <i class='bx bxs-cog bx-tada'></i> Technology Competence.</li>
            </ul>


          </div>


        </div>

      </div>
    </div>

  </div>
</section><!-- End Why Us Section -->