<app-nav-bar2></app-nav-bar2>

<div class=" container-fluid pagecontainer" style="margin-top: 90px;">
    <div class="headerimagecontainer">
        <div class="headercontent">
            <h1 data-aos="fade-left">do what you <img src="../../assets/img/haederlove.png" alt="Love"
                    class="img-responsive"> everyday</h1>
            <p>Want to join the SNW team? If you have a passion for planning & want to work for a rapidly growing
                entrepreneurial company, check out the listings below or send your resume to
                <span>careers@softnetworld.com</span>
            </p>
            <a data-toggle="modal" data-target="#myModal" href="#joblistings">send your resume</a>
        </div>
    </div>

    <!-- Modal -->
    <div id="myModal" class="modal fade my-4" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content" style="background-color: #a5a7b0;">
                <div class="modal-header">
                    <div class="text-center text-muted">
                        <h4 style="font-size: 22px; font-weight: 800;">Welcome To Softnet World</h4>
                    </div>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>

                </div>
                <div class="modal-body">

                    <form action="#">
                        <div class="form-group">
                            <input class="form-control" type="text" name="" id="" placeholder="Full Name">
                        </div>

                        <div class="form-group">
                            <input class="form-control" type="email" name="" id="" placeholder="Email Address">
                        </div>

                        <div class="form-group">
                            <input class="form-control" type="text" name="" id="" placeholder="Phone Number">
                        </div>

                        <div class="form-group">
                            <input class="form-control" type="text" name="" id="" placeholder="highest Qualification">
                        </div>

                        <div class="form-group">
                            <input class="form-control" type="text" name="" id="" placeholder="Your Profile">
                        </div>

                        <div class="form-group">
                            <label class="text-muted" style="font-size: 14px;" for="sel1">Total Number Of
                                Experience</label>
                            <select class="form-control" id="sel1">
                                <option>Less than 1</option>
                                <option>1 year</option>
                                <option>2 years</option>
                                <option>3 years</option>
                                <option>4 Years</option>
                                <option value="">5 years</option>
                                <option value="">More than 5 years</option>
                            </select>
                        </div>


                        <div class="form-group">
                            <label class="text-muted" style="font-size: 14px;" for="exampleFormControlFile1"><i
                                    class="icofont-attachment"></i> Attach Your Resume</label>
                            <input type="file" class="form-control-file" id="exampleFormControlFile1">
                        </div>


                        <div class="text-center">
                            <button style="width: 240px;" type="submit" class="btn btn-primary">Submit</button>
                        </div>



                    </form>
                </div>

            </div>

        </div>
    </div>


    <div class="benefitscontainer">
        <h2 data-aos="fade-up" style="font-weight: 600;">Join us and skyrocket your career</h2>
        <div>
            <p>Be a piece of the main web planning organization in Delhi. Softnet World holds a group of yearning,
                humane,
                devoted and serious web specialists, computerized advertisers, content makers and other critical
                individuals with the sole mission of changing organizations, changing their viewpoint and market
                presence to remain ahead in the opposition and under the spotlight. </p>

            <p>We trust in cooperating as a family and working in a climate that isn't just barely ideal however
                unwinding and fun. We groom every part in their particular fields and assist all with keeping their best
                foot forward in the business. </p>

            <p>We accept that fulfillment is an approach to deteriorate and consequently, we are continually propelling
                each other to gain some new useful knowledge consistently, face new difficulties, take a stab at
                development and arrive at a few achievements by conquering various difficulties and arise as an able
                expert in this field.</p>

            <p>In the event that you also have the potential, you can be a piece of this humane, vigorous and carefree
                group, and have a striking vocation and prosperous future!</p>
        </div>

    </div>



    <div class="benefitscontainer">
        <h2 data-aos="fade-up">benefits of being Softians</h2>
        <ul>
            <li class="card" data-aos="fade-up">
                <img data-aos="fade-left" src="../../assets/img/insurance.jpg" alt="competitive insurance">
                <h3>competitive Insurance</h3>
                <p>We provide health, dental and vision insurance options. Complementary life insurance is included too!
                </p>
            </li>

            <li class="card" data-aos="fade-up">
                <img data-aos="fade-left" src="../../assets/img/pto.jpg" alt="paid time off">
                <h3>paid time off</h3>
                <p>We believe that if you work hard, you get to play hard too. Enjoy paid time off in addition to all
                    main holidays.</p>
            </li>

            <li class="card" data-aos="fade-up">
                <img data-aos="fade-left" src="../../assets/img/retire.jpg" alt="retirement options">
                <h3>retirement options</h3>
                <p>We care about your retirement and give you the freedom to customize your 401(k) options.</p>
            </li>

            <li class="card" data-aos="fade-up">
                <img data-aos="fade-left" src="../../assets/img/attire.jpg" alt="casual attire">
                <h3>casual attire</h3>
                <p>We keep it classy yet casual, so feel free to wear what you feel comfortable in!</p>
            </li>

        </ul>
        <ul id="ul2">
            <li class="card" data-aos="fade-up">
                <img data-aos="fade-right" src="../../assets/img/coffee.jpg" alt="coffee and tea bar">
                <h3 id="bottom-adjustment">coffee & tea bar</h3>
                <p>Get the juices flowing every morning with complimentary coffee and tea.</p>
            </li>
            <li class="card" data-aos="fade-up">
                <img data-aos="fade-right" src="../../assets/img/community.jpg" alt="community">
                <h3 id="bottom-adjustment">community</h3>
                <p>We host happy hours, employee appreciation events, and some awesome team building events.</p>
            </li>
            <li class="card" data-aos="fade-up">
                <img data-aos="fade-right"
                    src="//s3-us-west-2.amazonaws.com/ec-cdn-content/ec-image-resources/1242376693_cali.jpg"
                    alt="india location">
                <h3 id="location"><br />Pune</h3>
                <p>B-1306, Majestique City, Wagholi, Pune – 412207, India !!
                </p>
            </li>
            <li class="card" data-aos="fade-up">
                <img data-aos="fade-right"
                    src="//s3-us-west-2.amazonaws.com/ec-cdn-content/ec-image-resources/231731189_texas.jpg"
                    alt="india location">
                <h3 id="location"> <br />Delhi</h3>
                <p>199, PKT-11
                    Sector A-6, Narela, Delhi, 110040
                    New Delhi, India !</p>
            </li>
        </ul>
    </div>


    <!-- current opening start -->

    <div class="benefitscontainer">


        <div class="col">
            <h2>CURRENT <b>OPENINGS</b></h2>
            <div class="tabs">
                <div class="tab">
                    <input class="acc_input" type="radio" id="rd1" name="rd">
                    <label class="tab-label" for="rd1">ANGULAR 6+,NODE JS DEVELOPER</label>
                    <div class="tab-content">
                        <h2>Job Description</h2>
                        <p>Please share resume to hr@softnetworld.com</p>
                        <h6>Experience : 4-6 Yrs <br> <br>

                            Location : Delhi</h6>
                        <p><b>Mandatory :</b></p>

                        <p style="margin-top: -40px;">Good skill on Angular 6+ <br>Good Skill on Node.Js</p>

                        <p><b>Optional:</b></p>
                        <p>knowledge on any database like PostreSQL/mySQL <br>
                            Knowledge of Python is added advantage</p>
                        <p><b>R&R for this profile is:</b></p>
                        <p>- Design and development using Angular 6+ /NodeJs technology and good command over it. <br>

                            - Should be well versed with developing responsive UI. <br>

                            - Should have an understanding of software development lifecycle. <br>

                            - Apply defined software best practices and procedures. <br>

                            - Should be aware of Agile development methodology <br>

                            - Participate in design and estimation discussions. <br>

                            - Decode requirements and come up with technical solutions for the requirement. <br>

                            - Well versed in software process methodologies, including CI/CD</p> <br>

                        <br> <br>



                    </div>

                </div>


                <div class="tab">
                    <input class="acc_input" type="radio" id="rd2" name="rd">
                    <label class="tab-label" for="rd2">MEAN STACK DEVELOPER</label>
                    <div class="tab-content">
                        Preferably at least 3 years of experience in development and design of internet applications
                        utilizing frontend and backend .Strong foundation and knowledge of frontend web technologies:
                        HTML/CSS/JavascriptAngularJS/Angular/ReactJS/VueJS frameworks and libraries for the
                        frontendExperienced using NodeJS platform and techniques for handling asynchronous calls
                        (Promise, Observables).Real time experience working on any cloud tech(AWS)Experience with
                        Laravel is most welcomed .Experience/Knowledge of page optimization for reducing load time and
                        SEOExperience/knowledge in source code versioning control using GIT, such as GITHUB will be
                        added advantageKnowledge of SDLC & Agile software development will be added advantageAble to
                        work independently as well as within a teamOpen to learning new technologies and trends.
                        Skills:- Javascript, Fullstack Developer, MEAN stack, NodeJS (Node.js) and MySQL
                    </div>
                </div>
                <div class="tab">
                    <input class="acc_input" type="radio" id="rd3" name="rd">
                    <label for="rd3" class="tab-close">Close others &times;</label>
                </div>
            </div>
        </div>



    </div>

    <!-- current opening end -->





</div>
<app-footer></app-footer>