<app-nav-bar2></app-nav-bar2>
<div  class="container-fluid" style=" margin-top: 80px;  padding-bottom: 30px;  background-attachment: fixed;  background-repeat: no-repeat;">
    <div class="row"  >
        <div class="col-md-6">
           
            <h1 class="text-uppercase text-muted text-center" style="font-weight: 900; margin-top: 152px; padding: 0 30px 10px 40px ; font-size: 25px;">A leading provider of IT services <br>
            </h1>
            <p class="text-justify text-muted" style="padding-left: 40px; padding-right: 40px;">Softnet World is a one-stop shop for all of your
                company's IT requirements. Softnet World is a group of
                vibrant and well-motivated people who are well-experienced in working with a wide range of operating
                systems, networks, and databases. At Softnet World, the client comes first, and we work hard to exceed
                expectations in terms of client satisfaction.</p>

                <div>
                    <div class="main-container">
                        <button style="font-weight: 800;" class="button text-muted" [routerLink]="['/contact-us']">REQUEST A QUOTE</button>
                    </div>
                </div>

        </div>

        <div class="col-md-6"> 
            <div>
                <img class="img-fluid" src="../../assets/img/graphics_dev1.png" alt="">
            </div>

        </div>



    </div>

</div>


<!-- card section start -->
<div>
    <h4 style="font-weight: 900;  margin-top: -40px;" class="text-center">OUR FEATURE WORK</h4>
</div>

<main role="main" class="container-fluid">

    <div class="row">
        <div class="col-md">
            <div class="starter-template">
                <ul class="nav nav-tabs" id="myTab" role="tablist" style="margin-bottom: 20px;">


                    <li class="nav-item" style="background-color: aqua;" >
                        <a style="padding: 12px 30px 12px 30px;" class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                            aria-controls="profile" aria-selected="true">Catalog</a>
                    </li>
                    <li class="nav-item" style="background-color: aqua;" >
                        <a style="padding: 12px 30px 12px 30px;" class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
                            aria-controls="contact" aria-selected="false">CMS</a>
                    </li>

                    <li class="nav-item" style="background-color: aqua;" >
                      <a style="padding: 12px 30px 12px 30px;" class="nav-link" id="e-commerce-tab" data-toggle="tab" href="#e-commerce" role="tab"
                          aria-controls="e-commerce-tab" aria-selected="false">E-commerce</a>
                  </li>

                  <li class="nav-item" style="background-color: aqua;" >
                    <a style="padding: 12px 30px 12px 30px;" class="nav-link" id="contact-tab" data-toggle="tab" href="#" role="tab"
                        aria-controls="contact" aria-selected="false">Live Classes</a>
                </li>

                <li class="nav-item" style="background-color: aqua;" >
                  <a style="padding: 12px 30px 12px 30px;" class="nav-link" id="contact-tab" data-toggle="tab" href="#" role="tab"
                      aria-controls="contact" aria-selected="false">e-Portal</a>
              </li>

                </ul>

                <!-- tab content starts here -->
                <div class="tab-content" id="myTabContent">



                    <!-- content 2 -->
                    <div class="tab-pane masonry-container fade show active " id="profile" role="tabpanel"
                        aria-labelledby="profile-tab">
                        
                        <div class="card-group " style="-ms-flex-align: center;">
                            <div class="card">
                                <img class="card-img-top"
                                    src="https://www.webicorp.com/wp-content/uploads/2021/07/risingcare-org.jpg"
                                    alt="Card image cap">
                                
                            </div>
                            <div class="card">
                                <img class="card-img-top"
                                    src="https://www.webicorp.com/wp-content/uploads/2021/07/sraqua24x7.jpg"
                                    alt="Card image cap">
                                
                            </div>

                            <div class="card">
                                <img class="card-img-top"
                                    src="https://www.webicorp.com/wp-content/uploads/2021/07/pestcontrol365-in.jpg"
                                    alt="Card image cap">
                               
                            </div>

                            <div class="card">
                                <img class="card-img-top"
                                    src="https://www.webicorp.com/wp-content/uploads/2021/07/servicezet.jpg"
                                    alt="Card image cap">
                                
                            </div>

                            <div class="card">
                                <img class="card-img-top"
                                    src="	https://www.webicorp.com/wp-content/uploads/2021/07/dramitabhasaha.jpg"
                                    alt="Card image cap">
                                
                            </div>
                        </div>


                        <div class="card-group " style="-ms-flex-align: center; margin-top: 22px;">
                          <div class="card">
                              <img class="card-img-top"
                                  src="https://www.webicorp.com/wp-content/uploads/2021/07/bawarchionline-in.jpg"
                                  alt="Card image cap">
                              
                          </div>
                          <div class="card">
                              <img class="card-img-top"
                                  src="https://www.webicorp.com/wp-content/uploads/2021/07/durbarsports-org.jpg"
                                  alt="Card image cap">
                             
                          </div>

                          <div class="card">
                              <img class="card-img-top"
                                  src="https://www.webicorp.com/wp-content/uploads/2021/07/theparkregency-in.jpg"
                                  alt="Card image cap">
                              
                          </div>

                          <div class="card">
                              <img class="card-img-top"
                                  src="https://www.webicorp.com/wp-content/uploads/2021/07/burnoutfitness-in.jpg"
                                  alt="Card image cap">
                             
                          </div>

                          <div class="card">
                              <img class="card-img-top"
                                  src="	https://www.webicorp.com/wp-content/uploads/2021/07/ainsw-in.jpg"
                                  alt="Card image cap">
                           
                          </div>
                      </div>
                    </div>

                    <!-- content 3 -->
                    <div class="tab-pane fade masonry-container" id="contact" role="tabpanel"
                        aria-labelledby="contact-tab">
                    
                        <div class="card-group">
                            <div class="card">
                                <img class="card-img-top"
                                    src="https://www.webicorp.com/wp-content/uploads/2021/07/moneyassist-in.png"
                                    alt="Card image cap">
                                <!-- <div class="card-body">
                                    <h5 class="card-title">Card title</h5>

                                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                </div> -->
                            </div>
                            <div class="card">
                                <img class="card-img-top"
                                    src="https://www.webicorp.com/wp-content/uploads/2021/07/busfam.jpg"
                                    alt="Card image cap">
                                
                            </div>

                            <div class="card">
                                <img class="card-img-top"
                                    src="	https://www.webicorp.com/wp-content/uploads/2021/07/riquezaconsultancy.jpg"
                                    alt="Card image cap">
                            
                            </div>

                            <div class="card">
                              <img class="card-img-top"
                                  src="https://www.webicorp.com/wp-content/uploads/2021/07/ainsw-in.jpg"
                                  alt="Card image cap">
                              
                          </div>

                          <div class="card">
                            <img class="card-img-top"
                                src="	https://www.webicorp.com/wp-content/uploads/2021/07/theparkregency-in.jpg"
                                alt="Card image cap">
                            
                        </div>

                        </div>


                            <!-- e-commerce -->
                    <div class="tab-pane fade masonry-container" id="e-commerce" role="tabpanel"
                    aria-labelledby="e-commerce-tab">
                
                    <div class="card-group">
                        <div class="card">
                            <img class="card-img-top"
                                src="https://www.webicorp.com/wp-content/uploads/2021/07/moneyassist-in.png"
                                alt="Card image cap">
                        </div>
                        <div class="card">
                            <img class="card-img-top"
                                src="https://www.webicorp.com/wp-content/uploads/2021/07/busfam.jpg"
                                alt="Card image cap">
                            
                        </div>

                        <div class="card">
                            <img class="card-img-top"
                                src="	https://www.webicorp.com/wp-content/uploads/2021/07/riquezaconsultancy.jpg"
                                alt="Card image cap">
                        
                        </div>

                        <div class="card">
                          <img class="card-img-top"
                              src="	https://www.webicorp.com/wp-content/uploads/2021/07/riquezaconsultancy.jpg"
                              alt="Card image cap">
                      
                      </div>

                      <div class="card">
                        <img class="card-img-top"
                            src="	https://www.webicorp.com/wp-content/uploads/2021/07/riquezaconsultancy.jpg"
                            alt="Card image cap">
                    
                    </div>

                       

                     

                    </div>
                </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

</main>



<!--card section end-->



<!-- ACCORDION FOR FAQ START -->
<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap" rel="stylesheet">
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
<body>
<div class="card-container">

  <!-- IMAGES CONTAINER -->
  <div id="left-side">
    <div class="container-img-desktop">
      <img class="woman-desk" src="../../assets/img/accordionsvg.svg" alt="woman">
    </div>
    <div class="container-img-mob">
      <img class="pattern-mobile" src="../../assets/img/bg-pattern-mobile.svg" alt="pattern">
      <img class="woman-mobile" src="../../assets/img/illustration-woman-online-mobile.svg" alt="woman">
    </div>
    <img class="box-img" src="../../assets/img/illustration-box-desktop.svg" alt="box">
  </div>

  <!-- TEXT CONTAINER -->
  <div id="right-side">
    <h1 style="font-size: 24px; font-weight: 900;">OUR SERVICES FAQ : </h1>
    <div class="question-container">
      <div class="question">
        <p>We are the best in providing the following services to our clients:</p>
        <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
      </div>
      <div class="hidden">
        <p>Linux Servers, Website Design, Social Media Marketing, Logo Design, Enterprise Solutions, Software
            Development on LAMP (Linux, Apache, MySql, Php), SEO (or, Digital Marketing), etc.</p>
      </div>
      
    </div>
    <div class="question-container">
      <div class="question">
        <p>What is Social Media Marketing?</p>
        <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
      </div>
      <div class="hidden">
        <p>"Social media marketing (SMM) is the practise of marketing the products and services of a company through
            social media websites and social networks."</p>  <br>
        <p>But, a main aspect of this definition is missing. Come, let us define it more simply and clearly:</p> <br>
        <p>"Social media marketing is the process of developing customised content for each social media platform in
            order to increase engagement and promote your business."</p> <br>
        <p>Social media marketing is concerned with engaging with the appropriate audience or customers and helping them
            better understand your company. It is substantially helpful to the enhancement of your business, by making
            your products more attractive.</p>
      </div>
    </div>
    <div class="question-container">
      <div class="question">
        <p>Why does your business need social media marketing?</p>
        <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
      </div>
      <div class="hidden">
          <p>The Social Media Marketing process involves writing blogs to be published on the company website, then
                creating some posters and marketing videos, and posting the blogs with the images and videos on the social
                media channels to promote the products and services of the company.</p>
      </div>
    </div>

    <div class="question-container">
      <div class="question">
        <p>Social Media Marketing provides us the following advantages:</p>
        <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down" class="arrow">
      </div>
      <div class="hidden">
        <ul style="padding-left: 30px;">
            <li>Social Media Marketing (SMM) continuously helps you to connect and interact with potential customers,
                and then it warms up the potential audience for your business.</li>
            <li> Social media marketing allows you to establish long-term relationships with your target audience.If you
                interact with the followers of your company on social media, or if you reply regularly to the queries
                about your products and services on social media, then the customers will trust you, and they will be a
                loyal audience to your brand or business.
                 </li>
        </ul>
      </div>
    </div>

    <div class="question-container">
      <div class="question">
        <p>Website Development !</p>
        <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
      </div>
      <div class="hidden">
        <p>The design of websites that are displayed on the internet is referred to as web design. Instead of software
            development, it generally refers to the user experience regarding web development. Web design
            is concentrated on designing websites for desktop browsers. Nevertheless, designing for mobile and tablet
            browsers' view of websites has become extremely crucial since the 2010s.</p> <br>
        <p>A web designer is someone who works on improving the look, layout, and, in some cases, content of a website.
            For example, appearance refers to the colours, font, and images utilised. The layout of information refers
            to how it is arranged and classified. A great web design is simple to use, visually appealing, and
            appropriate for the website's target audience and brand.</p>
        <h4 style="font-weight: 700; margin-top: 18px;">Logo Design</h4>
        <p>A company’s logo represents the brand of the company. It also sometimes represents an overview of the work
            done by the company.</p>
      </div>
    </div>
    
    <div class="question-container">
        <div class="question">
          <p>Logo Design !</p>
          <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
        </div>
        <div class="hidden">
            <p>A company’s logo represents the brand of the company. It also sometimes represents an overview of the work
                done by the company.</p>
        </div>
      </div>


      <div class="question-container">
        <div class="question">
          <p>How does logo design help build a target audience?</p>
          <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
        </div>
        <div class="hidden">
            <p>Creating trust and recognition with your audience and customers can be critical to the success of your
                business. When you place your logo on anything, you are officially endorsing it. And, like critics of film,
                food, etc. and other reviewers, this is significant. By having your logo designed on anything, you ensure
                that it lives up to the expectations you've set for your brand and that your customers believe it.Logos are
                well-known. That is one of the most important things a company's logo should do for the organization's
                success. Create respect and trust.</p> <br>
            <p>Consider the Nike logo. It is visible almost everywhere, and you always know what it is. Your company’s logo
                requires doing that thing to the acquired customers.</p>
           
            
        </div>
      </div>

      <div class="question-container">
        <div class="question">
          <p>Your Logo design differsentiates you from the competition !</p>
          <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
        </div>
        <div class="hidden">
            <p>Aside from renaming your company, your logo design can help you stand out from the crowd. Consider the
                contrast between McDonald's and Burger King. They are the two well-known and well-recognized burger chains.
                While Burger King has the better burgers, McDonald's has undoubtedly the more successful logo.</p> <br>
            <p>I'm sure you can picture the McDonald's logo in your head right now. The Burger King logo, on the other
                hand, could be a little hazy. Every time, the Golden Arches come out on top. You are aware of what they are.
                The logo makes me want to get into my car and drive out to get some McDonald's burgers.</p>
        </div>
      </div>

      <div class="question-container">
        <div class="question">
          <p>All Kinds of Cloud Support - AWS, Azure and Google Clouds</p>
          <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
        </div>
        <div class="hidden">
          <p>We provide all kinds of cloud services like AWS, Azure, Google Clouds and many more. </p>
        </div>
      </div>

      <div class="question-container">
        <div class="question">
          <p>Project Migration works at low cost !</p>
          <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
        </div>
        <div class="hidden">
          <p>If your website is running on some different framework or wordpress then we are here to help you out to develop on your demand. We are expert in project migration as well.</p>
        </div>
      </div>

      <div class="question-container">
        <div class="question">
          <p>24/7 Assistance Available  </p>
          <img src="../../assets/img/icon-arrow-down.svg" alt="arrow down"  class="arrow">
        </div>
        <div class="hidden">
          <p>In an IT context, 24/7 support means a support service that is provided 24 hours a day and 7 days a week. This may include an array of IT services – like server monitoring, call center support, database support and so on.</p>
        </div>
      </div>

  </div>
</div>


</body>

<!--ACCORDION FOR FAQ END-->

<app-footer></app-footer>