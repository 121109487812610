<section class="testimonial text-center" style="margin-top: 30px;">
    <div class="container">

        <div class="heading white-heading" data-aos="fade-up">
            WHAT OUR CLIENTS SAY
        </div>
        <div id="testimonial4"
            class="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
            data-ride="carousel" data-pause="hover" data-interval="5000" data-duration="2000">

            <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                    <div class="testimonial4_slide">
                        <img src="https://www.insead.edu/sites/default/files/assets/dept/mp/mba/images/jayanth_mantri.jpeg"  class="img-circle img-responsive">
                        <p>We've been astounded by the amazing value you provide, as well as how your efforts have resulted in higher page view times, lead generation, and significant reductions in bounce rate and necessary assistance.
                            Due to this reason, we depend on and will continue to depend on your company. </p>
                        <h4>Atul Verma</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="testimonial4_slide">
                        <img src="https://indiakellogg.files.wordpress.com/2011/11/phani_pic1.jpg" class="img-circle img-responsive" />
                        <p>We believe your amazing team will get our work done, because of your extensive software services expertise, incredible dependability, and super clear interaction and outstanding company. </p>
                        <h4>Rahul Singh</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="testimonial4_slide">
                        <img src="https://i.pinimg.com/originals/1c/c4/b0/1cc4b01ecf87277ec06faee809826b24.jpg" class="img-circle img-responsive" />
                        <p>Your team constantly outperforms our expectations, and every project we've worked on with you has been a huge success. You are an absolute asset to our national organization, and we plan to continue to collaborate with you as commercial alliances in the years ahead. </p>
                        <h4>Sneha Gupta</h4>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#testimonial4" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="carousel-control-next" href="#testimonial4" data-slide="next">
                <span class="carousel-control-next-icon"></span>
            </a>
        </div>
    </div>
</section>