  <!-- ======= Footer ======= -->
  <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">

    <div class="footer-newsletter">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h4>Our Newsletter</h4>
            <p style="color: aliceblue;">Subscribe Us For Latest Tech News And Best Running Offers :</p>
          </div>
          <div class="col-lg-6">
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/about-us']">About us</a></li>
              <!-- <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li> -->
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/terms-&-condition']">Terms of service</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/privacy-policy']" >Privacy policy</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/portfolio']" >Portfolio</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/career']" >Career</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/contact-us']" >Contact Us</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Main Services</h4>
            <ul>
              
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/web-development-and-designing']" >Web Development</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/ecoomerse-business']">e-commerce Business</a></li>
              <li><i class="bx bx-chevron-right"></i> <a  [routerLink]="['/digital-marketing']">Digital Marketing</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/wordpress-joomla']">Wordpress &  Joomla</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/cloud-services-and-support']" >Cloud Services & Support</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/email-services']" >E-mail Services</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/logo-design']"  >Logo Design</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/docker']" >Docker</a></li>
              <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/devops']" >DevOps</a></li>
              
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p style="color: aliceblue;">
                199, PKT-11<br>
                Sector A-6, Narela, Delhi, 110040<br>
              New Delhi, India <br><br>
              <strong>Phone:</strong> +91 88820 04118<br>
              <strong>Email:</strong> info@softnetworld.com<br>
            </p>

            <button style="border: none; padding: 8px; color: black; border-radius: 4px;"><a href="https://t.me/softnetworld1">Join Our Telegram Channel</a></button>

          </div>

          <div class="col-lg-3 col-md-6 footer-info">
            <h3>About Softnet World</h3>
            <p>Softnet world is one of the best website building company in the India, We don't develop things only , we also develop a relation. We give 100 percent satisfaction to our clients with our products.</p>
            <div class="social-links mt-3">
              <a href="https://twitter.com/SoftnetW" target="blank" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="https://www.facebook.com/softnetworldIT" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="https://www.instagram.com/softnetworld/" target="blank" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="https://www.linkedin.com/company/softnetworld" target="blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>Softnet World</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
       
      </div>
    </div>
  </footer><!-- End Footer -->