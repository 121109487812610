<app-nav-bar2></app-nav-bar2>
<section>
    <div style="margin-top: 20vh;" class="container">
        <div class="row">

            <div class="col-md-6">
                <div>
                    <img class="img-fluid" src="../../assets/img/Devops.svg.png" alt="devops services">
                </div>

            </div>


            <div class="col-md-6">
                <h2 style="margin-top: 80px; font-weight: 900;">DEVOPS SERVICES</h2>
                <p class="text-justify">DevOps is a set of practices that combines software development and IT operations. It aims to shorten
                    the systems development life cycle and provide continuous delivery with high software quality.
                    DevOps is complementary with Agile software development; several DevOps aspects came from the Agile
                    methodology.</p>

            </div>

            

        </div>

        <div class="row">
          

            <div class="col-md-6">
                <h2 style="margin-top: 38px; font-size: 22px; font-weight: 700;" >How DevOps helps in Your Business Growth</h2>
                <p class="text-justify">DevOps enables previously separate roles—development, IT operations, quality engineering, and
                    security—to synchronise and work cooperatively to create better, more good products. Incorporating a
                    DevOps culture, as well as DevOps processes and technologies, allows teams to better respond to the
                    requirements of customers, boost confidence in the applications they create, and accomplish business
                    goals more quickly. In this way, DevOps services are beneficial for your business.</p>

                <P class="text-justify">DevOps essentially integrates the evolution and operations of software and applications, allowing for
                    constant process delivery, whereas containerization is a strategy that aids in moving smoothly
                    through the whole software and application delivery methods, from creating, running, dispensing, and
                    managing the applications to their upkeep and maintenance. While these strategies are well-known,
                    they have only been easy with the support of tools and resources such as Docker.</P>
            </div>

            <div class="col-md-6">
                <div style="margin-top: 40px;">
                    <img class="img-fluid" src="../../assets/img/agile-methodology.gif" alt="grow business using devops">
                </div>

            </div>

            

        </div>

        <div class="row" style="margin-bottom: 40px;">
            <div class="col-md-6">
                <img class="img-fluid" src="../../assets/img/devopsgif.gif" alt="features of devops">

            </div>
            <div class="col-md-6">
             

                    <h4 style="font-size: 18px; margin-top: 70px; font-weight: 500;">One of the main features of DevOps is:</h4>
                    <h4 style="font-weight: 700;">Collaboration, transparency, and alignment</h4>
                    <p class="text-justify">Collaboration between teams, which begins with awareness, is one of the hallmarks of a good health
                        DevOps culture. Various teams, including development and IT operations, must interact with one
                        another about their DevOps methods, priorities, and considerations. They must also plan work in a
                        combined manner and agree on business-related objectives and outcomes of accomplishments.</p>
    
                

            </div>

        </div>

    </div>
</section>
<app-footer></app-footer>