import { EmailHostingComponent } from './email-hosting/email-hosting.component';
import { JobsupportAndTrainingServicesComponent } from './jobsupport-and-training-services/jobsupport-and-training-services.component';
import { WebDevelopmentAndDesigningComponent } from './web-development-and-designing/web-development-and-designing.component';
import { SocialMediaMarketingComponent } from './social-media-marketing/social-media-marketing.component';
import { CloudServicesAndSupportComponent } from './cloud-services-and-support/cloud-services-and-support.component';
import { LogoDesignComponent } from './logo-design/logo-design.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';

import { CyberSecurityComponent } from './cyber-security/cyber-security.component';
import { BrandingServicesComponent } from './branding-services/branding-services.component';
import { EcommerceBusinessComponent } from './ecommerce-business/ecommerce-business.component';
import { WindowServiceComponent } from './window-service/window-service.component';
import { LinuxServiceComponent } from './linux-service/linux-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { CareerComponent } from './career/career.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DevopsComponent } from './devops/devops.component';
import { DockerComponent } from './docker/docker.component';
import { KubernateComponent } from './kubernate/kubernate.component';
import { WordpressJoomlaComponent } from './wordpress-joomla/wordpress-joomla.component';



const routes: Routes = [
  
  { path: '', component: HomeComponent },
  {path: 'contact-us', component : ContactUsComponent},
  {path: 'portfolio', component: PortfolioComponent},
  {path:'about-us', component : AboutUsComponent},
  {path:'career', component : CareerComponent},
  {path : 'terms-&-condition',component: TermsConditionComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'linux-services', component : LinuxServiceComponent},
  {path:'window-services', component : WindowServiceComponent },
  {path : 'ecoomerse-business', component : EcommerceBusinessComponent},
  {path: 'branding-services', component : BrandingServicesComponent},
  {path : 'cyber-security', component: CyberSecurityComponent},
  {path : 'email-services', component : EmailHostingComponent},
  {path : 'digital-marketing', component: DigitalMarketingComponent},
  {path : 'logo-design', component : LogoDesignComponent},
  {path : 'cloud-services-and-support',component: CloudServicesAndSupportComponent},
  {path : 'social-media-marketing', component : SocialMediaMarketingComponent},
  {path : 'web-development-and-designing', component : WebDevelopmentAndDesigningComponent},
  {path : 'job-support-and-training',component : JobsupportAndTrainingServicesComponent},
  {path : 'devops', component : DevopsComponent},
  {path: 'docker', component : DockerComponent},
  {path: 'Kubernetes', component : KubernateComponent},
  {path : 'wordpress-joomla', component : WordpressJoomlaComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  
 }
