<app-nav-bar2></app-nav-bar2>

<body style="margin-top: 80px; height: 50vh;">
    <div class="title1 text text-center"
        style="font-size: 50px; margin-top: 0px; margin-bottom: 1s0px; padding-top: 105px; padding-bottom: 15px; font-weight: 900;">
        Simple, Powerful Email for Small Businesses</div>
    <p class="text-center text-muted" style="padding-bottom: 15px;">Give your business a professional makeover with
        email@yourbrand.com </p>
</body>

<div class="container">
    <div class="row">
        <div class="col-md-6">
            <h2 style="font-weight: 800; margin-top: 30px;">Get started with just the number of email accounts you need
                with 5GB/account
            </h2>

            <h5 style="margin-top: 25px; margin-bottom: 20px; font-size: 17px;" class="text-muted">Starts at just
                Rs.25.00/account/month</h5>

            <button class="btn " style="margin-bottom: 20px; background-color: #3775a8; color: white;"><a
                    [routerLink]="['/contact-us']">Contact Us</a></button>

        </div>



        <div class="col-md-6">
            <img data-aos="flip-left" src="../../assets/img/email-thepopweb.png" class="img-fluid my-4" alt="">

        </div>

        <!-- .....................................2nd section ............................. -->

        <div class="col-sm-4">
            <div class="card" data-aos="fade-up">
                <div class="card-body">
                    <h5 class="card-title">Intuitive and Responsive Design</h5>
                    <p class="card-text">Beautifully designed and delivered with the power of OpenXchange. You can also
                        access your email on your smartphone or tablet.</p>

                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card" data-aos="fade-up">
                <div class="card-body">
                    <h5 class="card-title">Inbuilt Virus Protection</h5>
                    <p class="card-text">Our advanced anti-virus technology secured your inbox and ensures that you are
                        protected from downloading malware and viruses.</p>

                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card" data-aos="fade-up">
                <div class="card-body">
                    <h5 class="card-title">5GB Storage Plus Backup</h5>
                    <p class="card-text">In addition to 5GB mail storage, your emails are backed up in our
                        state-of-the-art infrastructure so that you never lose important mails.</p>

                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card" data-aos="fade-up">
                <div class="card-body">
                    <h5 class="card-title">Intuitive and Responsive Design</h5>
                    <p class="card-text">Beautifully designed and delivered with the power of OpenXchange. You can also
                        access your email on your smartphone or tablet.</p>

                </div>
            </div>
        </div>


        <div class="col-sm-4">
            <div class="card" data-aos="fade-up">
                <div class="card-body">
                    <h5 class="card-title">Calendars, Contacts and Tasks</h5>
                    <p class="card-text">Manage contacts, create to-do lists and keep track of all your meetings in one
                        place with OpenXchange's productivity tools.</p>

                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card " data-aos="fade-up">
                <div class="card-body">
                    <h5 class="card-title">Social Media in your Inbox</h5>
                    <p class="card-text ">Now view your Facebook, Twitter, Google+ feeds in your Inbox itself.
                        OpenXchange brings all your social media under one roof.</p>

                </div>
            </div>
        </div>

        <!-- ........................section - 3 accordion ................ -->


        <div class="col-md-12 my-4">
            <h2 data-aos="fade-up" class="text-center" style="font-weight: 800;">FAQ – Your questions? We got answers !
            </h2>


            <div class="accordion-option text-muted" >


            </div>
            <div class="clearfix"></div>
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div data-aos="fade-up" class="panel panel-default">

                    <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title text-muted">
                            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                                How Will Purchasing Business Email benifit ?
                            </a>
                        </h4>
                    </div>

                    <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingOne">
                        <div class="panel-body text-justify">
                            As opposed to free email solutions, you can give your business a more professional image
                            with Business Email by getting email that is branded with your company’s domain name (ex.
                            sales@mybrand.com). In addition, you also benefit from our advanced email technology that
                            gives you the least latency and industry-best uptime, scalability and reliability. An email
                            service being served out of the cloud also means no IT, hardware, software, bandwidth or
                            people costs. And the best part is that you can add email accounts as and when your team
                            grows.
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingTwo">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                Which Email Clients and Protocol are Supported ?
                            </a>
                        </h4>
                    </div>


                    <div id="collapseTwo" class="panel-collapse collapse show" role="tabpanel"
                        aria-labelledby="headingTwo">
                        <div class="panel-body text-justify">
                            You can send and receive emails using any desktop-based email client such as Microsoft
                            Outlook, Outlook Express, Mozilla Thunderbird, Eudora, Entourage 2004, Windows Mail, etc. We
                            also have a guide on how you can configure different email clients to send/receive emails.
                            The enterprise email product supports the POP, IMAP and MAPI protocols.
                        </div>
                    </div>
                </div>


                <div data-aos="fade-up" class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                How DO I Use My Webmail Interface ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseThree" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingThree">
                        <div class="panel-body text-justify">
                            To access your Webmail Interface, you can use the white-labelled URL:
                            http://webmail.yourdomainname.com. Once on the log in page, you would need to login with
                            your email address and the corresponding password.
                        </div>
                    </div>
                </div>


                <div data-aos="fade-up" class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                Which Mobile Phone Can I Access My Mail Form ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseFour" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingFour">
                        <div class="panel-body text-justify">
                            Your email can be accessed using any Smartphone or Tablet. Our fluidic webmail, built on
                            HTML 5 & Javascript, is compatible on all major Operating systems such as iOS, Android,
                            Windows Mobile, Symbian and Blackberry.
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                What Is the Space Provided Per Email account ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseFive" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingFive">
                        <div class="panel-body text-justify">
                            Each email account comes with 5 GB space dedicated to emails.
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                What Port Do I Need To Use For Email Hosting ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseSix" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingSix">
                        <div class="panel-body text-justify">
                            Usually, the port used for the Outgoing Mail Server/SMTP Service is 25. However, there might
                            be a situation where your ISP might be blocking the use of port 25 for SMTP service. To
                            circumvent this you can use an alternate port 587 for sending mails.
                        </div>
                    </div>
                </div>


                <div data-aos="fade-up" class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                Can I Create Mailing List ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseSeven" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingSeven">
                        <div class="panel-body text-justify">
                            Yes, you can create mailing lists and add/delete users, select a moderator, restrict people
                            from joining a list or even ban users from a list. More information on this can be found in
                            our knowledge base.
                        </div>
                    </div>
                </div>


                <div data-aos="fade-up" class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                What Is Your SPAM Policy ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseEight" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingEight">
                        <div class="panel-body text-justify">
                            Yes, you can create mailing lists and add/delete users, select a moderator, restrict people
                            from joining a list or even ban users from a list. More information on this can be found in
                            our knowledge base.
                        </div>
                    </div>
                </div>


                <div data-aos="fade-up" class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                                Can I Use Auto Responder ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseNine" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingNine">
                        <div class="panel-body text-justify">
                            Yes, you can create mailing lists and add/delete users, select a moderator, restrict people
                            from joining a list or even ban users from a list. More information on this can be found in
                            our knowledge base.
                        </div>
                    </div>
                </div>



            </div>


        </div>





    </div>

</div>


<app-footer></app-footer>