<!-- ======= Services Section ======= -->
<section class="services my-5">
    <div class="container">

      <div class="row">
        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200" [routerLink]="['/linux-services']">
          <div class="icon-box icon-box-pink">
            <div class="icon"><i class='bx bx-laptop bx-burst' ></i></div>
            <h4 class="title"><a [routerLink]="['/linux-services']">Linux & Windows Services</a></h4>
            <p class="description">Softnet World is a leading Linux and windows service provider in India that offer...</p>
          </div>
        </div>

       

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200" [routerLink]="['/ecoomerse-business']">
          <div class="icon-box icon-box-green">
            <div class="icon"><i class='bx bxs-cart-alt bx-fade-up' ></i></div>
            <h4 class="title"><a [routerLink]="['/ecoomerse-business']">Ecommerce Business</a></h4>
            <p class="description">Electronic commerce or E-commerce is the modern way of trading which includes...</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200" [routerLink]="['/devops']">
          <div class="icon-box icon-box-blue">
            <div class="icon"><i class='bx bxl-dev-to bx-fade-right' ></i></div>
            <h4 class="title"><a [routerLink]="['/devops']">DevOps Services</a></h4>
            <p class="description">DevOps is the combination of cultural philosophies, practices, and tools that increases an organization's ability...</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200"  [routerLink]="['/docker']">
          <div class="icon-box icon-box-cyan">
            <div class="icon"><i class='bx bxs-file-doc bx-fade-left' ></i></div>
            <h4 class="title"><a [routerLink]="['/docker']">Docker Services</a></h4>
            <p class="description"> Most wanted and most loved developer tool, and helps millions of developers build, share and run any app, anywhere...</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200"  [routerLink]="['/Kubernetes']">
          <div class="icon-box icon-box-pink">
            <div class="icon"><i class='bx bxl-kubernetes bx-burst' ></i></div>
            <h4 class="title"><a [routerLink]="['/Kubernetes']">Kubernetes Services</a></h4>
            <p class="description">Also known as K8s, is an open-source system for automating deployment, scaling, and management...</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200"  [routerLink]="['/wordpress-joomla']">
          <div class="icon-box icon-box-cyan">
            <div class="icon"><i class='bx bxl-wordpress bx-spin' ></i></div>
            <h4 class="title"><a  [routerLink]="['/wordpress-joomla']">Wordpress &  joomla services</a></h4>
            <p class="description">WordPress and Joomla both are open-source Content Management System (CMS)....</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200" [routerLink]="['/social-media-marketing']">
          <div class="icon-box icon-box-green">
            <div class="icon"><i class='bx bxs-face bx-fade-up' ></i></div>
            <h4 class="title"><a [routerLink]="['/social-media-marketing']">Social Media Marketing</a></h4>
            <p class="description"> SMM, is a form of internet marketing that involves creating and sharing content on social media...</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200" [routerLink]="['/digital-marketing']">
          <div class="icon-box icon-box-blue">
            <div class="icon"><i class='bx bx-2x bx-radio-circle-marked bx-flashing' ></i></div>
            <h4 class="title"><a [routerLink]="['/digital-marketing']">Digital Marketing</a></h4>
            <p class="description">Component of marketing that utilizes internet and online based digital technologies such as desktop computers, mobile phones...</p>
          </div>
        </div>

        <!--3rd row-->

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200" [routerLink]="['/cloud-services-and-support']">
          <div class="icon-box icon-box-pink">
            <div class="icon"><i class='bx bxl-soundcloud bx-flashing' ></i></div>
            <h4 class="title"><a [routerLink]="['/cloud-services-and-support']">Cloud Service & Support</a></h4>
            <p class="description">The term "cloud services" refers to a wide range of services delivered on demand to companies and customers over the internet....</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200" [routerLink]="['/web-development-and-designing']">
          <div class="icon-box icon-box-cyan">
            <div class="icon"><i class='bx bxs-devices bx-burst' ></i></div>
            <h4 class="title"><a [routerLink]="['/web-development-and-designing']">Web Development & Designing</a></h4>
            <p class="description">Web development is the work involved in developing a Web site for the Internet or an intranet and we also focus on design parts too...</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200" [routerLink]="['/job-support-and-training']">
          <div class="icon-box icon-box-green">
            <div class="icon"><i class='bx bx-support bx-fade-up' ></i></div>
            <h4 class="title"><a [routerLink]="['/job-support-and-training']">Job Support & Training Services</a></h4>
            <p class="description">Softnet Worlds tie-up with many insitutes and corporate to provide best job support and training services best in the market. There is no any hidden charges...</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200" [routerLink]="['/logo-design']">
          <div class="icon-box icon-box-blue">
            <div class="icon"><i class='bx bxs-log-in-circle bx-spin' ></i></div>
            <h4 class="title"><a [routerLink]="['/logo-design']">Logo Design</a></h4>
            <p class="description">Build a custom logo for your brand, business, event, or even just for yourself. To create a logo that perfectly captures your vision. We provide best in the market...</p>
          </div>
        </div>

      </div>

    </div>
  </section><!-- End Services Section -->