import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branding-services',
  templateUrl: './branding-services.component.html',
  styleUrls: ['./branding-services.component.css']
})
export class BrandingServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
