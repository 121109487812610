import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobsupport-and-training-services',
  templateUrl: './jobsupport-and-training-services.component.html',
  styleUrls: ['./jobsupport-and-training-services.component.css']
})
export class JobsupportAndTrainingServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
