<app-nav-bar2></app-nav-bar2>
<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <h1 data-aos="flip-left"  style="margin-top: 20%; font-weight: 800; font-size: 30px;">Windows services !!</h1>
        <img style="margin-top: 20px;" class="img-fluid" width="220px" src="../../assets/img/heading-line-white.png"
            alt="">
        <p data-aos="flip-right" id="heroID" style="margin-top: 12px; font-size: 27px;">Softnet World is a Windows Services providing company
            run by a team of dynamic and highly motivated individuals equipped with years of experience and expertise in
            the Windows domain. Being a recognized and experienced Windows services providing firm in Delhi, Softnet
            World has been helping organizations achieve excellence through its advanced and innovative Windows
            Solutions and services.</p>
    </div>
</div>

<div style="background-color: #292929; margin-top: -40px;">
    <div class="container" style="padding-top: 30px;">
        <div class="row" style="padding-bottom: 30px;">
            <div class="col-md-8">
                <div class="text-center">
                    <img class="img-fluid" src="../../assets/img/9.jpg" alt="">
                </div>
    
                <div>
                    <p class=" my-4">
                        <br> <br>
                        Our expertise in providing innovative and unparalleled Windows solutions ensures a big boost in our
                        clients’ business functionality. Our tech team and industry experts are adept at delivering modern
                        solutions and this ensures a high client success rate. The team, well-versed with the Microsoft
                        platform and highly proficient in Microsoft functionalities, ensures perfect delivery of Windows
                        related services and solutions to the clients. Additionally, our team of experienced software
                        engineers is always ready to scrutinize operations in offices, outlets, distributed networks etc.
                        The services that we provide are focused at clients’ work-growth.</p>
                </div>
    
                <div >
                    <p>At Softnet World we provide professional services with experts having top qualifications and years of
                        experience in Windows services. Equipped with finest talent-worthy professionals and specialists,
                        our team delivers a comprehensive tech support. There are numerous benefits of using our services if
                        you are seeking a Windows service providing firm in Delhi.</p>
                </div>
    
                <div >
                    <ul>
                        <li>An experienced team capable of providing elegant windows services for various businesses</li>
                        <li>Our flexible work procedure lets us deliver the best solutions for Windows.</li>
                        <li>The only windows services providing firm in Delhi with 24/7 customer support.</li>
                        <li>Trained and experienced consultants backed by a refined and robust architecture.</li>
                        <li>Less response time ensures quick service.</li>
                    </ul>
                </div>
    
    
            </div>
    
            <div class="col-md-4">
                <div >
                    <app-other-services></app-other-services>
                </div>
    
            </div>
    
        </div>
    
    </div>
</div>




<app-footer></app-footer>