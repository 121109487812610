<app-nav-bar2></app-nav-bar2>
<div class="hero-image" style="height: 90vh; padding-top: 90px !important;">
      <div class="hero-text">
            <h1 data-aos="zoom-in-down" class="text-center" style="font-style: Georgia; color: #fff; font-size:50px;">
                  Social Media & Marketing </h1>
            <p data-aos="flip-right" class="text-center">we provide best in the market</p>

      </div>




</div>


<div class="container">
      <div class="row">
            <div class="col-md-12">
                  <p class="text-muted">Social media marketing aims at utilizing social media handles to envelope more
                        audience with your brand. Amongst several marketing strategies, social media marketing has posed
                        itself as one of the best ways to engage with potential customers and communicating with them.
                        The ubiquitous use of social media platforms like Facebook, Instagram, and Twitter etc. extends
                        to a major portion of the population today which makes leveraging social media for marketing
                        effectiveness. </p>

                  <p class="text-muted">Softnet World specializes in social media marketing services and enables brands
                        to tap into conversations with their customers. We build for you a clear social marketing
                        strategy keeping in mind your business requirements. Being the best social media marketing firm
                        in India, Softnet World offers you high-quality solutions to your media marketing problems. Our
                        SMM strategy includes:</p>

                  <ol class="text-muted">
                        <li style="margin-top: 16px;">Market research and analysis according to new trends</li>
                        <li style="margin-top: 16px;">Campaign monitoring through reporting and analyzing competitor activities.</li>
                        <li style="margin-top: 16px;">Content production on social media</li>
                        <li style="margin-top: 16px;">Managing SMM campaign activities</li>
                        <li style="margin-top: 16px;">Customer identification</li>
                  </ol>

                  <p class="text-muted">Our experts at Softnet World ensure a well thought and detailed SMM campaign
                        that aligns with your business goals. You can contact us to be your best social media marketing
                        firm in India. Our idea is to promote your business by bringing more people to your network and
                        expanding your reach by boosting brand image and popularity. Social media marketing plays a
                        vital role in marking the digital presence of a brand. Softnet World being the best social media
                        marketing firm in India understands the customer’s needs and our experts with specialization in
                        audience targeting and engaging help you to make the best out of social advertising and
                        marketing.</p>

                    <ul class="text-muted">
                          <li style="margin-top: 16px;">Backed by a skilled team and experienced experts we deliver quality services to take marketing campaigns to next level.</li>
                          <li style="margin-top: 16px;">We help you speak through all social media platforms and target your audience.</li>
                          <li style="margin-top: 16px;">We manage your rapport and offer individual attention.</li>
                          <li style="margin-top: 16px;">We assure you success and positive results.</li>
                    </ul>    
            </div>

      </div>

</div>

<app-footer></app-footer>