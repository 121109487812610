import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linux-service',
  templateUrl: './linux-service.component.html',
  styleUrls: ['./linux-service.component.css']
})
export class LinuxServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
