<app-nav-bar2></app-nav-bar2>
<!-- Hero section start -->
<main style="margin-top: 6em;">
    <div>
        <!-- Left Aligned -->
        <section id="left-flex">
            <div class="shado-banner ribbon">
                <div class=" title-wrapper ">
                    <h1 data-aos="fade-right" class="no-margin no-border" style="font-weight: 800; font-size: 30px;">Linux Services !! </h1>
                    <img style="margin-top: 20px;" class="img-fluid" width="220px"
                        src="../../assets/img/heading-line-white.png" alt="">
                    <p data-aos="fade-left" style="margin-top: 20px;">Softnet is an all-in-one IT service management
                        platform with a robust set of features to help you design, plan, deliver, operate and control IT
                        services. You can manage network devices, perform patching, access remotely, deploy and run
                        antivirus scans, automate scripts across all devices and more.</p>
                </div>
            </div>
        </section><!-- /end Left Aligned -->
        <!-- <p class="spacer"></p> -->



    </div>
</main>
<!-- heros section end -->


<div style="background-color: #4376b3; ; ">
    <div class="container">
        <div class="row" style=" padding-bottom: 40px ; padding-top: 20px;">
            <div class="col-md-8 ">
                <div class="my-4">
                    <p >Softnet World is a leading Linux service provider in India that offers
                        cost-effective open-source
                        solutions and support for the design and maintenance of Linux systems in order to meet the needs of
                        its clients. The security of business data of organizations is of prime concern these days and thus
                        the primary focus of Soft-net World is to provide a stable and secure solution tailored to your
                        needs.</p>


                </div>

                <div >
                    <p>The team of consultants, engineers, and developers at Softnet World is well-equipped to cater to all
                        your Linux-related requirements. We provide installation support, recommendations, and solutions to
                        IT Engineers based on their day-to-day challenges.</p>
                </div>
                
                <div class="text-center">
                    <img class="img-fluid" src="../../assets/img/linuxServices.jpg" alt="linux services">
                </div>
    
               
    
                
    
                <div >
                    <p>Soft-net World provides technical services over a wide range of Linux-based platforms which include
                        several variants of Linux such as Redhat, Fedora, Ubuntu, CentOS, and others. At Soft-net World, we
                        offer several managed</p>
                </div>
    
                <p >services and solutions for Linux such as:-</p>
                <ul style="color: aliceblue;">
                    <li>Mail Server</li>
                    <li>Database Server</li>
                    <li>Firewall Server</li>
                    <li>SVN Server</li>
                    <li>VPN Server</li>
                    <li>DNS Server</li>
                    <li>Linux Security</li>
                    <li>Other Linux Services</li>
                </ul>
    
                <p >The proficiency of the expert team at Softnet World helps them provide you with
                    high-end performance
                    Linux-based services and solutions from simple to complex application based server installations.
                    Softnet World always focuses on providing top-notch services and this has paved the way for it to be the
                    best Linux service provider in Delhi. The salient features that help us stand apart from the rest
                    include:-</p>
    
                <ul >
                    <li>Quick response and dedicated support with engineers at your disposal.</li>
                    <li>We ensure high-quality work and customer satisfaction is our priority.</li>
                    <li>We offer 24/7 monitoring of Linux based systems.</li>
                    <li>Linux software and hardware diagnosis.</li>
                </ul>
    
            </div>
    
            <div class="col-md-4">
                <app-other-services></app-other-services>
    
            </div>
    
        </div>
    
    </div>
</div>

<app-footer></app-footer>