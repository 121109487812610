<app-nav-bar2></app-nav-bar2>
<div class="container-fluid " style="padding-left: 0px; padding-right: 0px; overflow: hidden;  margin-top: 70px;">
   

</div>
<section class="about-section" style="margin-top: -40px;">
    <div class="container">
        <div class="row clearfix">

            <!--Content Column-->
            <div class="content-column col-md-6 col-sm-12 col-xs-12">
                <div class="inner-column">
                    <div class="sec-title">
                        <div class="title">About Us</div>
                        <h2 class="text-muted text-uppercase" data-aos="fade-up" style="font-weight: 600; font-size: 20px !important;">We Are The Leader
                            In <br> The Web
                            Development</h2>
                    </div>
                    <div data-aos="fade-up" class="text">Softnet world is one of the best website building company in
                        the India, We don't develop things only , we also develop a relation. We give 100 percent
                        satisfaction to our clients with our products. </div>

                    <a [routerLink]="['/contact-us']" class="theme-btn btn-style-three">Contact Us</a>
                </div>
            </div>

            <!--Image Column-->
            <div class="image-column col-md-6 col-sm-12 col-xs-12">
                <div class="inner-column " data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div class="image">
                        <img data-aos="fade-left"
                            src="https://www.aaravinfotech.com/assets/images/pages/web-designing-services-15d6a50cd896d5.svg"
                            alt="">
                        
                    </div>
                </div>
            </div>



            <div data-aos="fade-up" class="col-md-12 col-sm-12 col-xs-12">
                <h2 class="sec-title text-muted" style="font-weight: 400; font-size: 25px;">WHY US</h2>
                <p class="text-muted">Although it doesn’t require a genius to figure out that the needs and requirements
                    of every business
                    differ significantly, developing such customized, tailor-made and business-specific solutions
                    definitely does. ‘Softnet World’ has that genius. We patiently listen to the specific needs of our
                    clients, formulate adifferent model for each and every client (base on their requirements) and
                    develop highly effective solutions that help our clients’ businesses tread the path of growth.</p>

            </div>


            <div data-aos="fade-up" class="col-md-12 col-sm-12 col-xs-12">
                <h2 style="font-weight: 400;" class="sec-title text-muted text-uppercase">About Softnet World</h2>
                <p class="text-muted">A leading IT Services providing firm, Softnet World is the one stop solution to
                    all the IT needs of
                    your business. Softnet World is a team of dynamic and highly motivated individuals capable of
                    handling a variety of Operating Systems, networks and databases. We providethe best Windows and
                    Linux solutions and services that cater to the needs and requirements of a number of businesses. In
                    the open source domain, we excel in providing our clients the best services with regards to:-</p>

            </div>

            <div data-aos="fade-up" class="col-md-12 col-sm-12 col-xs-12">
                <p class="text-muted">Softnet World is quite versatile as far as the areas it covers are concerned. From
                    network management
                    to product support to cloud computing to IT and data security services, we have got you covered on
                    every front. At Softnet World, client is the topmost priority and we strive hard to excel in terms
                    of client satisfaction. While we continuously better our serviceswith the use of enhanced tools and
                    technologies, it clearly reflects in the quality of our work.</p>
            </div>

            <div data-aos="fade-up" class="col-md-12 col-sm-12 col-xs-12">
                <p class="text-muted">The expanse of services offered by Softnet World is vast.Softnet World does not
                    just cover IT
                    services, it also delves into the realms of Social Media services as well. The following social
                    media services are provided by the Softnet World team:-</p>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12">
                <div data-aos="fade-up">
                    <ul class="text-muted">
                        <li style="margin-top: 15px;">Search Engine Optimization (SEO)- We help your website rank higher
                            in Google search results.</li>
                        <li style="margin-top: 15px;">Social Media Optimization (SMO)-Let us enhance the brand awareness
                            of your business. We garnerample publicity for your business.</li>
                        <li style="margin-top: 15px;">Website Designing- We create attractive websites for your business
                            that let you be known and heard worldwide.</li>
                        <li style="margin-top: 15px;">Online advertising- Cut short all your exorbitant advertisement
                            expenses. Let us take charge and witness the magic of online advertising for your business
                        </li>
                        <li style="margin-top: 15px;">Logo Design- A beautiful and meaningful logo speaks volumes about
                            abrand. We design the most breath-taking logos for your business and help you stand apart.
                        </li>
                    </ul>
                </div>

            </div>

            <div data-aos="fade-up" class="col-md-12 col-sm-12 col-xs-12">
                <p class="text-muted">Hand over the reins of your business to the team at Softnet World and witness your
                    business grow by leaps and bounds.</p>
                <p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Est quisquam laboriosam
                    atque delectus labore at vero nesciunt officiis nihil asperiores cum accusantium obcaecati
                    cupiditate doloribus reiciendis qui explicabo ex soluta, iure itaque. Sed illum non laudantium,
                    commodi cumque ipsa, laboriosam iste quasi corporis quaerat velit.</p>

            </div>

            <div data-aos="fade-up" class="col-md-12 col-sm-12 col-xs-12">
                <h2 style="font-weight: 400;" class="sec-title text-muted text-uppercase text">Boost Your Business</h2>
                <p class="text-muted">Let our innovative digital marketingstrategies and solutions drive your business
                    towards growth and expansion.</p>
                <p class="text-muted">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Animi sint quam maiores
                    ipsa eligendi corporis at adipisci quia, quos temporibus laboriosam consectetur et debitis
                    recusandae corrupti cumque doloribus magnam tenetur ab necessitatibus totam? Sed neque eos laborum
                    ratione assumenda in id dolorum maiores minus consequuntur? Magnam culpa, vitae magni recusandae
                    iusto architecto illo consectetur, velit corporis, molestiae nemo sint cumque commodi vero totam
                    distinctio! Recusandae amet repudiandae earum suscipit perspiciatis.</p>

            </div>

            <div data-aos="fade-up" class="col-md-12 col-sm-12 col-xs-12">
                <h2 style="font-weight: 400;" class="sec-title text-muted text-uppercase">Software</h2>
                <p class="text-muted">The Windows and Linux software solutions developed by our team enhance the
                    productivity of employees, thereby boosting efficiency.</p>
                <p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo omnis accusantium ut
                    veniam dolorem ab, dignissimos recusandae quaerat, ea eos facilis ipsam labore unde rerum adipisci
                    fuga distinctio id sunt. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis accusantium
                    aspernatur ullam autem repudiandae magnam fuga totam beatae rerum impedit, maxime eius asperiores!
                    Natus ut voluptas ad facilis necessitatibus dolores doloribus cupiditate quo quaerat!</p>

            </div>

        </div>
    </div>
</section>

<app-footer></app-footer>