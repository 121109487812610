import { TechnologyUsedComponent } from './home/technology-used/technology-used.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {HeroComponent} from './home/hero/hero.component';
import { ServiceCompComponent } from './home/service-comp/service-comp.component';
import { FooterComponent } from './home/footer/footer.component';
import { WhyUSComponent } from './home/why-us/why-us.component';
import { FeaturesComponent } from './home/features/features.component';
import { TestimonalComponent } from './home/testimonal/testimonal.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NavBar2Component } from './nav-bar2/nav-bar2.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CareerComponent } from './career/career.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LinuxServiceComponent } from './linux-service/linux-service.component';
import { OtherServicesComponent } from './other-services/other-services.component';
import { WindowServiceComponent } from './window-service/window-service.component';
import { EcommerceBusinessComponent } from './ecommerce-business/ecommerce-business.component';
import { BrandingServicesComponent } from './branding-services/branding-services.component';
import { CyberSecurityComponent } from './cyber-security/cyber-security.component';

import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { LogoDesignComponent } from './logo-design/logo-design.component';
import { CloudServicesAndSupportComponent } from './cloud-services-and-support/cloud-services-and-support.component';
import { SocialMediaMarketingComponent } from './social-media-marketing/social-media-marketing.component';
import { WebDevelopmentAndDesigningComponent } from './web-development-and-designing/web-development-and-designing.component';
import { JobsupportAndTrainingServicesComponent } from './jobsupport-and-training-services/jobsupport-and-training-services.component';
import {EmailHostingComponent} from './email-hosting/email-hosting.component';
import { DevopsComponent } from './devops/devops.component';
import { DockerComponent } from './docker/docker.component';
import { KubernateComponent } from './kubernate/kubernate.component';
import { WordpressJoomlaComponent } from './wordpress-joomla/wordpress-joomla.component'




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeroComponent,
    ServiceCompComponent,
    FooterComponent,
    TechnologyUsedComponent,
    WhyUSComponent,
    FeaturesComponent,
    TestimonalComponent,
    ContactUsComponent,
    NavBar2Component,
    PortfolioComponent,
    AboutUsComponent,
    CareerComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    LinuxServiceComponent,
    OtherServicesComponent,
    WindowServiceComponent,
    EcommerceBusinessComponent,
    BrandingServicesComponent,
    CyberSecurityComponent,
    
    DigitalMarketingComponent,
    LogoDesignComponent,
    CloudServicesAndSupportComponent,
    SocialMediaMarketingComponent,
    WebDevelopmentAndDesigningComponent,
    JobsupportAndTrainingServicesComponent,
    EmailHostingComponent,
    DevopsComponent,
    DockerComponent,
    KubernateComponent,
    WordpressJoomlaComponent
    
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
