import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-window-service',
  templateUrl: './window-service.component.html',
  styleUrls: ['./window-service.component.css']
})
export class WindowServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(".jumbotron").css({ height: $(window).height() + "px" });

$(window).on("resize", function() {
  $(".jumbotron").css({ height: $(window).height() + "px" });
});

  }

}
