<app-nav-bar2></app-nav-bar2>
<div class="container">
    <div class="row" style="margin-top: 8em;">
        <div class="col-md-6">
            <h4 style="font-weight: 800; margin-top: 2em;" >Kubernates Services</h4>
            <p class="text-justify text-muted">Operating applications become more difficult as they grow to span multiple containers implemented across
                multiple servers. Kubernates offers an open source API that controls how and where those containers run
                to manage this complexity.</p>

                <p  class="text-justify text-muted">Kubernetes is an open-source container-orchestration system for automating computer application deployment, scaling, and management. It was originally designed by Google and is now maintained by the Cloud Native Computing Foundation.</p>

                <p class="text-justify text-muted">Kubernetes, also known as K8s, is an open-source system for automating deployment, scaling, and management of containerized applications.</p>

        </div>

        <div class="col-md-6">
            <div class="text-center">
                <img class="img-fluid" src="../../assets/img/kubernates.svg" alt="kubernates services">
            </div>
            
        </div>

        <div class="row">
            <div class="col-md-12">
                
                <h3 class="text-center my-4" style="font-weight: 700;">HOW KUBERNATES IS ADVANTAGEOUS FOR GROWING YOUR WORK</h3>
                <p class="text-justify text-muted">Keeping containerised apps functioning can be difficult since they frequently involve several containers implemented across multiple machines. Kubernates allows users to manage and utilise containers, as well as scale them to the required state and manage their lifecycles. One can utilise Kubernates to build portable, customizable, and expandable container-dependent applications. So, the Kubernates service would be really beneficial for your business.</p>

            </div>

            <div class="col-md-12">
                <h4 class="text-center my-4" style="font-weight: 700;">Some Major benefits of Using Kubernates : </h4>
                <div class="text-center">
                    <img class="img-fluid" src="../../assets/img/kubernates features.png" alt="kubernates features">
                </div>

                <div class="text-center">
                    <img class="img-fluid" src="../../assets/img/kubernates features2.jpg" alt="kubernates features">
                </div>

            </div>

        </div>

    </div>

</div>
<app-footer></app-footer>