<app-nav-bar2></app-nav-bar2>


<main class="hero">
    <section class="container">
        <!-- article -->
        <article>
            <!-- title -->
            <h1 class="title-hero" style="font-weight: 900;">Digital Marketing</h1>
            <!-- subtitle -->
            <p class="sub-title-hero" style="margin-top: 8px; padding-right: 10px;">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores fugiat doloribus architecto consequatur quidem aliquam fugit fuga quam, omnis laborum repellendus odit? Illo, placeat. Consequatur ea unde, rerum aspernatur minus beatae eaque aliquam reprehenderit. Nobis assumenda exercitationem aliquam nihil neque?. .</p>
            <div class="btn-container">
                <!-- buttons call to action -->
                <a [routerLink]="['/contact-us']" class="btn btn-hero"> <i class="fas fa-arrow-right"></i> Contact us</a>
                
            </div>
        </article>
        <!-- figure -->
        <figure>
            <img data-aos="flip-up" src="../../assets/img/digitalMarketinghero.png" alt="description">
            <figcaption>description</figcaption>
        </figure>
    </section>
</main>


<div class="container">
    <div class="row">
        <div class="col-md-8 my-4">
            <p class="text-muted">We at Softnet World offer our clients with the prevailing Web Hosting services so that
                their business can catch up with the online competition and outgrow them. We offer everything our
                customers need to be unbeaten online, like business pages, email, and hosting. Our web hosting network
                is entirely based on the highly reliable and hasty operating systems like Linux which is monitored 24×7.
            </p>

            <p class="text-muted">Softnet world not only offers trustworthy and fast web hosting services but also
                ensures affordability
                which makes us the Best hosting service provider in India. We make sure that this technology performs
                consistently with no errors so that we can put forward services like web hosting and domain
                registrations to our customers and keep them satisfied in terms of speed, reliability, and economy. From
                hosting your website to safeguarding your online business we make it all happen. Our customers get</p>

            <div>
                <ul class="text-muted" style="line-height: 39px;">
                    <li>Free Setup And Domain Names</li>
                    <li>Professionally Designed Websites</li>
                    <li>Word Press Ready Hosting</li>
                    <li>A Visitor Friendly Performance</li>
                    <li>Protection From Hackers</li>
                    <li>Unlimited Websites And Domains</li>
                </ul>
            </div>

            <p class="text-muted">We also offer different web hosting plans so that customers can choose accordingly. We
                do exactly what we claim and this is the sole reason why our customers have awarded us with the tag of
                the Best hosting service provider in India company.</p>

            <p class="text-muted">We know the hassles and risks of domain transfers and website migration which is why
                we have the best
                team of professionals who make sure that your websites are set up on our powerful, secured and
                uninterrupted servers. We assist you in every step that you take before after the setting up of the
                server, no matter what the challenge is. It is our aim to ensure that your online presence is not just
                an existence, but successful, professional, consistent, secured and productive!</p>

            <p class="text-muted">
                Check out our services and do not give a thought to budget as we offer our hosting plans at highly
                competitive prices with no hidden cost. Softnet World also offers award-winning customer support to all
                its clients irrespective of their industry and requirements. So go ahead and pay us a visit on the
                website today!
            </p>

        </div>

        <div class="col-md-4 my-4">

            <app-other-services></app-other-services>

        </div>


    </div>

</div>






<app-footer></app-footer>