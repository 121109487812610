import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-development-and-designing',
  templateUrl: './web-development-and-designing.component.html',
  styleUrls: ['./web-development-and-designing.component.css']
})
export class WebDevelopmentAndDesigningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
