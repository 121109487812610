<!-- ======= Features Section ======= -->
<section class="features" style="margin-top: 70px;">
    <div class="container">

      <div class="section-title text-center">
        <h2 style="font-weight: 900;" class="my-4">FEATURES</h2>
        <p style="color: gray;">We, at Soft Net World, provide the best quality and affordable IT services in India, and also globally. We specialize in imparting the following IT services to our clients:.</p>
      </div>

      <div class="row my-5" data-aos="fade-up">
        <div class="col-md-5 my-4">
          <img src="../../../assets/img/feature1.png" class="img-fluid" alt="">
        </div>
        <div class="col-md-7 pt-4">
          <h3>Tools to bring our company together.</h3>
          <!-- <p class="font-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.
          </p> -->
          <ul>
            <p>Web Designing, Logo Designing, E-Commerce, Website SEO, Website Re-design, Web Hosting, Email Hosting, Domain Registration, Custom Website Design, School ERP, Bulk SMS marketing/E-mail marketing, Software Development, Cloud Services, DevOps Services, Website Promotion and Branding Services, Linux and Windows, AWS, Digital Certificate, Social Media Marketing, Google Business Gsuite, Job Support and Training, etc.</p>
            <p>We work with various programming languages such as HTML, PHP, CSS, RUBY, JS, ASP, etc.</p>
          </ul>
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-5 order-1 order-md-2">
          <img src="../../../assets/img/erp1.png" class="img-fluid" alt="">
        </div>
        <div class="col-md-7 pt-5 order-2 order-md-1">
          <h3>School ERP (Enterprise Resource Planning) System</h3>
          <p class="font-italic">
            This software is used mostly by schools to manage regular tasks and events such as administrative work, student data, fee maintenance, assignment management, class attendance, e-learning monitoring, etc.
          </p>
          <!-- <p>
            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum
          </p> -->
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-5">
          <img src="../../../assets/img/devops-loop-illustrations.png" class="img-fluid" alt="">
        </div>
        <div class="col-md-7 pt-5">
          <h3>DevOps Technology</h3>
          <p>DevOps is the combination of people, processes, and technology to continuously offer value to customers. It consists of development (Dev) and operations (Ops). Teams that incorporate DevOps become high-performing, and accomplish business objectives such as, increasing the time to market, enhancing the mean time to recovery, etc.</p>
          <!-- <ul>
            <li><i class="icofont-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
            <li><i class="icofont-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
            <li><i class="icofont-check"></i> Facilis ut et voluptatem aperiam. Autem soluta ad fugiat.</li>
          </ul> -->
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-5 order-1 order-md-2">
          <img src="../../../assets/img/domain-registration.png" class="img-fluid" alt="">
        </div>
        <div class="col-md-7 pt-5 order-2 order-md-1">
          <h3>Website Domain Registration</h3>
          <p class="font-italic">
            The act of retaining a name on the Internet for a particular amount of time (about one year, is widely recognized as domain name registration. It is essential to understand that this domain will exclusively be yours for as long as you renew it, and so there is no other way to purchase a domain name indefinitely.
          </p>
         
        </div>
      </div>


      <div class="row" data-aos="fade-up">
        <div class="col-md-5 order-1 order-md-2">
          <img src="../../../assets/img/togetherdevelopment.png" class="img-fluid" alt="">
        </div>
        <div class="col-md-7 pt-5 order-2 order-md-1">
          <h3>Work together with your favourite software and tools</h3>
          <p class="font-italic">
            Our company, SoftNet’s software services connect your employees among themselves with tools such as AWS, Google suite, Office 365, Cloud services, etc.
          </p>
         
        </div>


        
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-5 order-1 order-md-2">
          <img src="../../../assets/img/aws.png" class="img-fluid" alt="">
        </div>
        <div class="col-md-7 pt-5 order-2 order-md-1">
          <h3>AWS (Amazon Web Services)</h3>
          <p class="font-italic">
            One of the most significant advantages of cloud computing is the ability to replace upfront capital infrastructure costs with low variable costs that specifically deal with your business. Firms no longer need to arrange and obtain the necessary servers and certain other IT infrastructure before time, and the outcomes can be delivered more speedily.
          </p>
         
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-5">
          <img src="../../../assets/img/G-Suite-Altn-Technology.png" class="img-fluid" alt="">
        </div>
        <div class="col-md-7 pt-5">
          <h3>Benefits provided by G Suite (Google Suite)</h3>
         
          <ul>
            <li><i class="icofont-check"></i> Gmail: Use your professional email address, such as bob@yourcompany.com, to send and receive mail.</li>
            <li><i class="icofont-check"></i> Calendar: Send calendars to make it easier to plan meetings and events..</li>
            <li><i class="icofont-check"></i> Docs: Work together on online documents, spreadsheets, and presentations in real time.</li>
            <li><i class="icofont-check"></i> Drive: Securely store and archive data in the cloud.</li>
            <li ><i class="icofont-check"></i> Meet: Participate in video meetings using their laptop or other device.</li>
          </ul>
        </div>
      </div>

    </div>
  </section><!-- End Features Section -->