<app-nav-bar2></app-nav-bar2>

<body style="padding-right: 40px;">
    <div class="container" >
        <div class="row" >
                 <div class="col-md-12">
                     <h2 class="text-center text-uppercase" style="font-weight: 900; font-size: 45px; color: rgb(14, 13, 13); margin-bottom: 30px;  text-shadow: 2px 2px gray; margin-top: 60px; ">Logo Designing</h2>

                 </div>
                 
                    
                      <div class="col-md-4">
                        <div class="card wiggle">
                          <h3 class=" align-self-center" style="font-size: 20px;">STARTER </h3>
                          <h2 class=" align-self-center">INR 1500</h2>
                          <img data-aos="fade-left" src="../../assets/img/starterlogo.png" class="img-fluid align-self-center" alt="">
                          <p>2 Design Concepts</p>
                          <p>2 Revisions </p>
                          <button [routerLink] = "['/contact-us']" class="btn">
                                  CONTACT NOW <span class="badge badge-primary"></span>
                          </button>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="card wiggle">
                          <h3 class=" align-self-center" style="font-size: 20px;">PREMIUM </h3>
                          <h2 class=" align-self-center">INR 4500</h2>
                          <img data-aos="fade-up" src="../../assets/img/premiumlogo.png" class="img-fluid align-self-center" alt=""> 
                          <p>Flash Animated Logo</p>
                          <p>1 Design Concept, 2 Revisions </p>
                          <button [routerLink] = "['/contact-us']" class="btn">
                                  CONTACT NOW <span class="badge badge-primary"></span>
                          </button>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="card wiggle">
                          <h3 class=" align-self-center" style="font-size: 20px;">BEST BUY  </h3>
                          <h2 class=" align-self-center">INR 2500</h2>
                          <img data-aos="fade-right" src="../../assets/img/bestbuylogo.png" class="img-fluid align-self-center" alt=""> 
                          <p>4 Design Concepts</p>
                          <p>2 Revisions </p>
                          <button [routerLink] = "['/contact-us']" class="btn">
                                  CONTACT NOW <span class="badge badge-primary"></span>
                          </button>
                        </div>
                      </div>

                     

                     
                   
                  
        </div>

    </div>
</body>


<app-footer></app-footer>