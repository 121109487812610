<app-nav-bar2></app-nav-bar2>
<section class="section section-top" style="margin-top: 80px;">
      <div class="content rellax" data-rellax-speed="5">
            <h1 data-aos="zoom-in-down" style="font-weight: 900 !important; font-stretch: expanded; color: aliceblue;">
                  Web Development &
                  Designing.</h1>
           
      </div>
</section>
<section class="section section-stream">
      <img class="play rellax" src="https://image.flaticon.com/icons/svg/4069/4069204.svg" alt="Play"
            data-rellax-speed="0" data-rellax-xs-speed="-5" />
      <div class="content rellax" data-rellax-speed="10">
            <div>
              
                  <p class="text-muted text-justify" style="margin-top: -20px;">
                        Web design and development have existed for nearly as long as websites have persisted. It used
                        to have a much easier definition because building a website was a much easier method.
                  </p>
            </div>
            <div>

                  <p class="text-muted text-justify">
                        When you compare the first website, which was launched in 1991, to the websites of nowadays, you
                        can see how far websites have progressed. Building and implementing a website is more
                        complicated nowadays, requiring an entire ecosystem of responsibilities and technical skills.
                  </p>

                  <p class="text-muted text-justify">It can be tough for designers to figure out where they fit in this
                        ecosystem. This article describes the main aspects of the website development process, creating
                        a strong portrayal of your role, the roles of others, and the skill sets required.</p>

                  <h4 style="margin-top: 30px; font-weight: 800;">What is web development and web design?</h4>
                  <p class="text-muted text-justify">The method of constructing a website is referred to as web design
                        and development. It entails two significant skill sets, as the name implies: web design and web
                        development. Web design specifies how a website looks and feels, whereas web development defines
                        how it actually works.</p>

                   <h4 style="margin-top: 30px; font-weight: 800;" >Design vs. front-end development vs. back-end development</h4>   
                   <p class="text-muted text-justify">To keep things simple, let's divide website creation into two categories: what users see and what users don't see.</p>  
                   <p class="text-muted text-justify">What the user views is created in a browser using design and front-end development. Design defines a website's layout, colours, images, and fonts —everything that contributes to a website's branding and usability—and necessitates the use of tools such as Photoshop, Sketch, Illustrator, and Fireworks.</p>
                   <p class="text-muted text-justify">Front-end development means the process of implementing a design using coding languages such as HTML, CSS, and Javascript. Users can interact with buttons, images, text, contact forms, and navigation menus using these languages. They are also essential to responsive and adaptive design.</p>
                   <p class="text-muted text-justify">What the user does not see happens on a server and requires back-end development.</p>
                   <p class="text-muted text-justify">A website requires a back end to gather and organise all data received from the front end. When a user purchases something or fills out a form, they are entering data into an application on the front end of the website. And that data is saved in a database that is hosted on a server.</p>
                   <p class="text-muted text-justify">A website operates in the manner you want it to because the front and back ends are constantly communicating. A back-end developer is analogous to a conductor. They ensure that applications, databases, and servers work in tandem by employing languages such as Ruby, PHP,.Net, and Python, as well as frameworks such as Ruby on Rails.</p>
                   <h4 style="margin-top: 30px; font-weight: 800;">Elements of Web Design</h4>
                   <p class="text-muted text-justify">"Design is a problem-solving action," famous designer Paul Rand mentioned in his essay, The Politics of Design. "It allows you to clarify, synthesise, and dramatise a word, an image, a product, or an occurrence."</p>

                  <div style="margin-left: 30px;">
                        <ol class="text-muted" style="margin-top: 10px;">
                              <li style="margin-top: 16px;"> Layout: The layout of a website is the configuration of its header, navigation menu, footer, content, and graphics.</li>
                              <li style="margin-top: 16px;">Visual Hierarchy: A user should be able to obtain the data they require by simply taking a peek at a website.</li>
                              <li style="margin-top: 16px;">Navigation: Straightforward, efficient navigation allows users to instantly get the information they seek.</li>
                              <li style="margin-top: 16px;">Colour: Colour provides a website personality, makes it stand out, and instructs the customer on how to proceed..</li>
                              <li style="margin-top: 16px;">Graphics: Graphics include the pictures, logos, videos, and icons that are present on a website.</li>
                              <li style="margin-top: 16px;">Speed: A customer’s first impression depends on how much time is required to open the website.</li>
                        </ol>
                  </div>
            </div>
      </div>
</section>


<app-footer></app-footer>