<app-nav-bar2></app-nav-bar2>
<div class="hero-image" style="height: 100%; margin-top: 80px !important;">
  <div class="hero-text">
    <h1 class="text-center" style="font-style: Georgia; color: #fff; font-size:50px;">Cyber Security</h1>
    <p class="text-center">we provide best in the market</p>

  </div>


  <div id="popup1" class="overlay">
    <div class="popup">
      <a class="close" href="#">&times;</a>
      <div data-aos="fade-up" class="content">
        <br><br>
        <h2>Promotional Information & Exclusions</h2>
        This is a list of exclusions
        <br><br><br>
      </div>
    </div>
  </div>
  <!--lightbox style popup goes here-->
</div>

<div class="container my-4">
  <div class="row">
    <div class="col-md-8">
      <div class="text-center">
        <img class="img-fluid" src="../../assets/img/cybersecurityImage.jpg" alt="">

      </div>

      <p class="text-muted my-4">At present, a large number of companies are focusing on launching apps for different
        platforms,
        transformational journeys, sticking to the latest technologies, relying on the cloud computing solutions,
        offering the faster app development services and working on innovative IT operational processes. It has led to a
        plethora of new threats to the security of these companies. This is the main reason why it is vital to cope up
        with the security issues in order to become a successful IT service provider Do you need the best cyber security
        solution in India? Well, you are at the right place!</p>

      <p class="text-muted">We, at Softnet world, help our clients in reaching the next level of security for their
        companies. Our IT
        security experts have different solutions to offer including the security program assessment, the cyber security
        strategy buildup, and the risk management. We endeavor to identify the key requirements and preferences of our
        clients and their core business industries when it comes to the transformation, strategisation and
        implementation of our security services. We are completely transparent with our clients, delivering excellent
        and result-driven solutions. Our team also ensures that they cover all the essential phases of cyber security.
      </p>
      <p  style="font-size: 22px; font-weight: 800;">Services offered _</p>

      <ol class="text-muted" style="margin: 2px;">
        <li style="margin-top: 16px !important;">Security Program Stratagem</li>
        <li style="margin-top: 16px !important;">Enterprise Risk and Compliance</li>
        <li style="margin-top: 16px !important;">Enterprise Event Management</li>
        <li style="margin-top: 16px !important;">Security Architecture and Execution</li>
        <li style="margin-top: 16px !important;">Managed Security Services</li>
        <li style="margin-top: 16px !important;">Identity and Access Management</li>
      </ol>

      <p class="text-muted my-4">In this world of modern technology and innovative techniques, we have a deep expertise in
        cyber security services, which tend to defend against the growing universe of cyber hazards. Our cyber security
        solutions can meet the unique security challenges an IT company needs to face regularly.</p>
  
      <p class="text-muted">
        With our broader experience, our clients can fill in whatever gaps there are in the security competences and
        implement new systems and strategize for a better and much more secured environment Thus, when seeking the best
        cyber security solution in India to improve your IT security management, we are the most popular and leading
        organization. Stay connected with us to learn more about our cyber security services!
      </p>
    </div>

    

    <div class="col-md-4">
      <div style="margin-bottom: 30px;">
        <app-other-services></app-other-services>
      </div>

    </div>

  </div>

</div>
<app-footer></app-footer>