<app-nav-bar2></app-nav-bar2>
<div class="container">
    <div class="row">
        <div style="margin-bottom: 60px;" class="col-md-12">
            <h2 style="margin-top: 150px; font-size: 24px; " class="shop-men-women text-center">Privacy Policy</h2>

            <p class="text-muted" style="margin-top: 40px;">
                The privacy policy shall disclose all the privacy practices for the service provider. It applies solely
                to the collected information and you will be notified of the following. </p>

            <ol class="text-muted">
                <li>All the Personally identifiable information that is collected from you through the services provided
                    would be justified with details as to why and whom that is to be shared.</li> <br>
                <li>We will avail you with the choices that regard the use of your data.</li> <br>
                <li>Every security detail that goes in to protect your data from exploitation.</li> <br>
                <li>Providing you with details for procedures entailing the options to correct any inaccuracies in your
                    information.</li> <br>
            </ol>

            <p class="text-muted">We are the sole proprietor of the information collected through our services. The data
                and information that we receive are the only given voluntarily by the users via email or through direct
                contact. Any data received (Voluntarily) will not be sold or given out to anyone under any
                circumstances.</p>

            <p class="text-muted">The information provided shall be used to only get in touch with you to enquire about
                the services you
                are looking for. Your information won’t be sold/rented to any third party working outside our working
                structure but only on instances that includes deliveries or shipping an order unless we are instructed
                not to via mail or any other written notices. Any moderation can be sent via email which would be in
                effect immediately.</p>

            <p class="text-muted">
                You are liable to cut off from us at any time you’d like to. To avail the specifics of such
                circumstances, be sure to notify via mail or any other contact information provided to you by us. You
                can raise the following concerns:
            </p>

            <ol class="text-muted">
                <li>The details of all the information that we have on you or if any</li> <br>
                <li>The details of all the information that we have on you or if any</li>   <br>
                <li>Delete data that we have on you</li> <br>
                <li>Any related concerns you might be having about the data we have on you</li>
            </ol>

            <p class="text-muted">
                Your information is protected under a layer of security measures taken online and offline. Any sensitive
                information shared is under encryption and in a way is transmitted to us in any way. You can also verify
                this by looking at the address of the webpage which ideally should be having a lock icon at the very
                beginning of the web address.
            </p>

            <p class="text-muted">
                The encryptions used online is not limited to there as the information is also handled sensitively
                offline ensuring its safety too. Employees who are employed to provide you with a service are granted
                access to this information but for a limited period of time. All the information collected are stored in
                servers which are under scrutiny and on layers of security (Online/Offline).
            </p>

            <p class="text-muted">
                To avail our services, the users must first complete the registration step where they are asked to
                provide us with information that will help us contact them for queries. The information will solely be
                used for the purpose of contacting the users and won’t be vulnerable to any kinds of exploits.
            </p>

            <p class="text-muted">
                Cookies are used on your site which also isn’t limited to us as they are also being used by our business partners.
            </p>
        </div>

    </div>

</div>
<app-footer></app-footer>