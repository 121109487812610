import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(".question").click(function(){ 
     
      $(this).toggleClass("question-active");
      $(this).next().slideToggle(700);
      $(this).children("img.arrow").toggleClass("arrow-active");
  });
  }

}
